import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { openAlertDialog } from "_actions";
import { fetchAssignGateway, fetchReleaseGateway, fetchTenantList } from "_services";

class GatewayDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTenantId: "",
            tenants: []
        };

        this._handleChange = this._handleChange.bind(this);
        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleRelease = this._handleRelease.bind(this);
        this._handleAssign = this._handleAssign.bind(this);
    }

    _handleOnClose() {
        this.props.onClose();
    }

    _handleRelease() {
        this.props.dispatch(
            openAlertDialog("연결 해제하시겠습니까?", true, "연결해제", "취소", () => {
                this.props.fetchReleaseGateway({ tenantid: this.props.gateway.tenantid, gatewayids: [this.props.gateway.gatewayid], reqseq: this.props.gateway.reqseq }, (error, result) => {
                    console.log(error, result);
                    if (result) {
                        this.props.dispatch(
                            openAlertDialog("연결 해제되었습니다.", false, null, null, () => {
                                this.props.onClose();
                                window.location.reload();
                            })
                        );
                    } else {
                        this.props.dispatch(openAlertDialog("연결을 해제할 수 없습니다.", false, null, null, () => {}));
                    }
                });
            })
        );
    }

    _handleAssign() {
        if (this.state.selectedTenantId) {
            this.props.fetchAssignGateway({ tenantid: this.state.selectedTenantId, gatewayids: [this.props.gateway.gatewayid], reqseq: this.props.gateway.reqseq || 1 }, (error, result) => {
                if (result) {
                    this.props.dispatch(
                        openAlertDialog("연결되었습니다.", false, null, null, () => {
                            this.props.onClose();
                            window.location.reload();
                        })
                    );
                } else {
                    this.props.dispatch(openAlertDialog("연결할 수 없습니다.", false, null, null, () => {}));
                }
            });
        } else {
            this.props.dispatch(openAlertDialog("테넌트를 선택해주세요.", false, null, null, () => {}));
        }
    }

    _handleChange(e) {
        const { value } = e.target;
        this.setState({ ...this.state, selectedTenantId: value });
    }

    componentDidMount() {
        this.props.fetchTenantList({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    tenants: result.filter((item) => item.status === "3"),
                    selectedTenantId: this.props.gateway.tenantid || ""
                });
            }
        });
    }

    render() {
        const gateway = this.props.gateway;
        console.log(gateway);
        return (
            <>
                {gateway && (
                    <Modal onShow={this._handleShow} show={this.props.open} onHide={this.props.onClose} backdrop="static" dialogClassName="modal-dialog-centered">
                        <Modal.Header>
                            <h5 className="modal-title">게이트웨이 연결</h5>
                            <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <form className="form_area">
                                        <div className="form-group">
                                            <label>디바이스ID</label>
                                            <input type="text" className="form-control" readOnly value={gateway.gatewayid}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>테넌트</label>
                                            <select className="form-select" value={this.state.selectedTenantId} onChange={this._handleChange}>
                                                {!gateway.tenantid && <option value="">테넌트를 선택하세요.</option>}
                                                {this.state.tenants.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.tenantid}>
                                                            {item.compnm}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="btn_area">
                                {gateway.tenantid && (
                                    <button type="button" className="btn btn_black" onClick={this._handleRelease}>
                                        연결해제
                                    </button>
                                )}
                                <button type="button" className="btn btn_black" onClick={this._handleAssign}>
                                    연결
                                </button>
                                <button type="button" className="btn" onClick={this._handleOnClose}>
                                    닫기
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTenantList,
            fetchAssignGateway,
            fetchReleaseGateway,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(GatewayDetail);
export { connected as GatewayDetail };
