import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { openAlertDialog } from "_actions";
import { addBulletin, answerQnA, modifyBulletin } from "_services";

class QnAAnswerModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            upbltid: "",
            contents: ""
        };

        this._handleShow = this._handleShow.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSave = this._handleSave.bind(this);
    }
    _handleShow() {
        const { data } = this.props;
        if (data.replies.length === 0) {
            this.setState({
                ...this.state,
                upbltid: data.bltid
            });
        } else {
            this.setState({
                ...this.state,
                bltid: data.replies[0].bltid,
                contents: data.replies[0].contents
            });
        }
    }
    _handleChange(e) {
        const { id, value } = e.target;
        this.setState({ ...this.state, [id]: value });
    }
    _handleSave() {
        const { data } = this.props;

        if (data.replies.length === 0) {
            this._addAnswer();
        } else {
            this._modifyAnswer();
        }
    }
    _addAnswer() {
        const { upbltid, contents } = this.state;
        const params = { upbltid, contents };

        this.props.answerQnA(params, (error, data) => {
            if (!error) {
                this.props.dispatch(
                    openAlertDialog("답변이 등록되었습니다.", false, null, null, () => {
                        this.props.onClose();
                        this.props.history.goBack();
                    })
                );
            }
        });
    }
    _modifyAnswer() {
        const { contents } = this.state;
        const { data } = this.props;

        const params = { bltid: data.replies[0].bltid, contents };
        this.props.modifyBulletin(params, (error, data) => {
            if (!error) {
                this.props.dispatch(
                    openAlertDialog("수정되었습니다.", false, null, null, () => {
                        this.props.onClose();
                        this.props.history.goBack();
                    })
                );
            }
        });
    }
    render() {
        const { contents } = this.state;
        const { data } = this.props;
        return (
            <Modal onShow={this._handleShow} show={this.props.open} onHide={this.props.onClose} backdrop="static" dialogClassName="modal-dialog-centered">
                <Modal.Header>
                    <h5 className="modal-title">답변하기</h5>
                    <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                </Modal.Header>
                <Modal.Body>
                    <form className="form_area">
                        <div className="form-group">
                            <label>제목</label>
                            <input type="text" id="title" name="title" className="form-control" defaultValue={data.title} readOnly />
                        </div>
                        <div className="form-group">
                            <label>작성자</label>
                            <input type="text" id="compnm" name="compnm" className="form-control" defaultValue={data.compnm} readOnly />
                        </div>
                        <div className="form-group">
                            <label className="asterisk">내용</label>
                            <textarea className="form-control" style={{ height: 150 }} defaultValue={data.contents} readOnly></textarea>
                        </div>
                        <div className="form-group">
                            <label className="asterisk">내용</label>
                            <textarea id="contents" className="form-control" style={{ height: 150 }} placeholder="내용을 입력해 주세요." value={contents} onChange={this._handleChange}></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn_area">
                        <button type="submit" className="btn btn_black" onClick={this._handleSave} disabled={contents.trim() === ""}>
                            {data.replies.length === 0 ? "답변하기" : "답변수정"}
                        </button>
                        <button type="button" className="btn" onClick={this.props.onClose}>
                            취소
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addBulletin,
            modifyBulletin,
            answerQnA,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(QnAAnswerModal);
export { connected as QnAAnswerModal };
