import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { openAlertDialog } from "_actions";
import { fetchSendMail } from "_services";

class SendMailModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            contents: "",
            to: props.data.email
        };

        this._handleChange = this._handleChange.bind(this);
        this._handleSave = this._handleSave.bind(this);
    }

    _handleChange(e) {
        const { id, value } = e.target;
        this.setState({ ...this.state, [id]: value });
    }
    _handleSave() {
        this._sendMail();
    }
    _sendMail() {
        const { title, to, contents } = this.state;
        const params = { to, title, contents };
        this.props.fetchSendMail(params, (error, data) => {
            if (!error) {
                this.props.dispatch(
                    openAlertDialog("발송되었습니다.", false, null, null, () => {
                        this.props.onClose();
                        window.location.reload();
                    })
                );
            }
        });
    }

    render() {
        const { title, contents } = this.state;
        return (
            <Modal onShow={this._handleShow} show={this.props.open} onHide={this.props.onClose} backdrop="static" dialogClassName="modal-dialog-centered">
                <Modal.Header>
                    <h5 className="modal-title">메일발송</h5>
                    <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                </Modal.Header>
                <Modal.Body>
                    <form className="form_area">
                        <div className="form-group">
                            <label className="asterisk">제목</label>
                            <input type="text" id="title" className="form-control" placeholder="제목을 입력해 주세요." value={title} onChange={this._handleChange} />
                        </div>
                        <div className="form-group">
                            <label className="asterisk">수신자</label>
                            <input type="text" id="to" className="form-control" readOnly value={this.state.to} />
                        </div>
                        <div className="form-group">
                            <label className="asterisk">내용</label>
                            <textarea id="contents" className="form-control" placeholder="내용을 입력해 주세요." value={contents} onChange={this._handleChange}></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn_area">
                        <button type="submit" className="btn btn_black" onClick={this._handleSave} disabled={title.trim() === "" || contents.trim() === ""}>
                            발송
                        </button>
                        <button type="button" className="btn" onClick={this.props.onClose}>
                            닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchSendMail,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(SendMailModal);
export { connected as SendMailModal };
