/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TenantManagementLnbView, GatewayDetail } from "_containers";
import { fetchGatewayList } from "_services";
import MaterialReactTable from 'material-react-table';

class GatewayManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allGateways: [],
            tenants: [],
            openGatewayDetail: false,
            selectedGateway: "",
            keyword: "",
            rowCount: 10,
            page: 1,
            waitCount: 0
        };

        this._handlePageChange = this._handlePageChange.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleGatewayDetail = this._handleGatewayDetail.bind(this);
        this._handleKeywordChange = this._handleKeywordChange.bind(this);
    }

    _handleKeywordChange(e) {
        let { value } = e.target;
        value = (value || "").trim();
        this.setState({ ...this.state, keyword: value });
    }

    _handleClose() {
        this.setState({
            ...this.state,
            openGatewayDetail: false,
            selectedGateway: ""
        });

        // this._loadData();
    }

    _handleGatewayDetail(item) {
        this.setState({
            ...this.state,
            openGatewayDetail: true,
            selectedGateway: item
        });
    }

    componentDidMount() {
        this._loadData();
    }

    _loadData() {
        this.props.fetchGatewayList({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    allGateways: result,
                    filteredGateways: result,
                    tenants: result.filter((item) => item.payment_status != null),
                    waitCount: result.filter((item) => item.status === "1").length
                });
            }
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber
        });
    }

    render() {
        let gateways = this.state.allGateways;
        if (this.state.keyword) {
            gateways = gateways.filter((item) => [item.gatewayid, item.compnm, item.assingedtime, item.registeredtime].join().includes(this.state.keyword));
        }
        const columns = [
            {
                header: "디바이스ID", accessorKey: "gatewayid", size: 120
            },
            {
                header: "테넌트명", accessorKey: "compnm"
            },
            {
                header: "등록일시", accessorKey: "registeredtime", size: 100
            },
            {
                header: "연결일시", accessorKey: "assingedtime", size: 100
            },
        ]
        return (
            <>
                <div className="content">
                    <div className="inner">
                        <TenantManagementLnbView {...this.props} waitCount={this.state.waitCount} />

                        <div className="main_cont">
                            <div className="cont_header">
                                <h3 className="title">게이트웨이관리</h3>
                            </div>
                            <div className="cont_body">
                                <section className="tab_sect">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="notiAll" aria-labelledby="notiAll-tab">
                                            <div className="table_area">
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={gateways || []}
                                                    initialState={{ showGlobalFilter: true }}
                                                    localization={{
                                                        noRecordsToDisplay: "데이터가 없습니다.",
                                                        noResultsFound: "데이터를 찾을 수 없습니다.",
                                                    }}
                                                    muiTablePaginationProps={{
                                                        SelectProps: { native: false },
                                                        labelRowsPerPage: '보기',
                                                        labelDisplayedRows: function defaultLabelDisplayedRows({ page }) { return `${page + 1} 페이지`; }
                                                    }}
                                                    muiSearchTextFieldProps={{
                                                        placeholder: '검색',
                                                        sx: { minWidth: '18rem' },
                                                        variant: 'outlined',
                                                    }}

                                                    muiTableBodyRowProps={({ row }) => ({
                                                        onClick: () => { this._handleGatewayDetail(row.original) },
                                                        sx: { cursor: 'pointer' },
                                                    })}
                                                    enableColumnFilters={false}
                                                    enableDensityToggle={false}
                                                    enableHiding={false}
                                                    enableSorting={true}
                                                    enableFullScreenToggle={false}
                                                    enableGlobalFilter={true}
                                                    enableFilters={true}
                                                    enableBottomToolbar={true}
                                                    enableTableHead={true}
                                                    enableTableFooter={true}
                                                    enableToolbarInternalActions={true}
                                                    enableTopToolbar={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.openGatewayDetail && <GatewayDetail gateway={this.state.selectedGateway} open={this.state.openGatewayDetail} onClose={this._handleClose} />}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchGatewayList,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(GatewayManagement);
export { connected as GatewayManagement };
