import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeAlertDialog } from "_actions";

class Alert extends Component {
    constructor(props) {
        super(props);

        window.fetch.dispatch = this.props.dispatch;
    }
    onClose = () => {
        this.props.dispatch(closeAlertDialog());
    };
    render() {
        return (
            <Modal
                show={this.props.open}
                onHide={() => {
                    this.onClose();
                    this.props.handleNo && this.props.handleNo();
                }}
                backdrop={this.props.confirm ? "static" : true}
                dialogClassName="modal-dialog-centered message_modal"
            >
                <Modal.Header>
                    <h5 className="modal-title">{this.props.title ? this.props.title : "안내"}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            this.onClose();
                            this.props.handleNo && this.props.handleNo();
                        }}
                    ></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="message" dangerouslySetInnerHTML={{ __html: this.props.message }}></div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn_area">
                        {this.props.confirm && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() => {
                                    this.onClose();
                                    this.props.handleNo && this.props.handleNo();
                                }}
                            >
                                {this.props.labelNo ? this.props.labelNo : "취소"}
                            </button>
                        )}

                        <button
                            type="button"
                            className={this.props.confirm ? "btn btn_red" : "btn"}
                            onClick={() => {
                                this.onClose();
                                this.props.handleOk && this.props.handleOk();
                            }}
                        >
                            {this.props.labelOk ? this.props.labelOk : "확인"}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const { open, message, confirm, labelOk, labelNo, handleOk, handleNo } = state.alert;
    return {
        open,
        message,
        confirm,
        labelOk,
        labelNo,
        handleOk,
        handleNo,
    };
}
const connected = connect(mapStateToProps)(Alert);
export { connected as Alert };
