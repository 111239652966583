import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { configureStore, configureFakeBackend } from "_helpers";

const store = configureStore();
configureFakeBackend();

const startApp = () => {
    render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById("root")
    );
};

startApp();
