import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { CustomerServiceLnbView, NoticeWriteModal } from "_containers";
import { pathConstant } from "_constants";
import { openAlertDialog } from "_actions";
import { removeBulletin, getLocalSession } from "_services";

class NoticeDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this._handleRemove = this._handleRemove.bind(this);
        this._handleOpenWriteNotice = this._handleOpenWriteNotice.bind(this);
        this._handleCloseWriteNotice = this._handleCloseWriteNotice.bind(this);
    }
    componentDidMount() {}
    _handleRemove() {
        this.props.dispatch(
            openAlertDialog("삭제하시겠습니까?", true, "삭제", "취소", () => {
                if (this._checkLogined()) {
                    const { data } = this.props.location.state;
                    const params = { bltid: data.bltid };
                    this.props.removeBulletin(params, (error, data) => {
                        if (!error) {
                            this.props.dispatch(
                                openAlertDialog("삭제되었습니다.", false, null, null, () => {
                                    this.props.history.goBack();
                                })
                            );
                        }
                    });
                }
            })
        );
    }
    _checkLogined() {
        const session = getLocalSession();
        if (!session) {
            this.props.history.replace(pathConstant.PATH_LOGIN);
            return;
        }
        return true;
    }
    _handleOpenWriteNotice() {
        const session = getLocalSession();
        if (!session) {
            this.props.history.replace(pathConstant.PATH_LOGIN);
            return;
        }

        this.setState({
            ...this.state,
            writeNoticeOpen: true
        });
    }
    _handleCloseWriteNotice() {
        this.setState({
            ...this.state,
            writeNoticeOpen: false
        });
    }
    render() {
        const { data } = this.props.location.state;
        const { writeNoticeOpen } = this.state;
        return (
            <div className="content">
                <div className="inner">
                    <CustomerServiceLnbView {...this.props} />

                    <div className="main_cont">
                        <div className="cont_header">
                            <h3 className="title">공지사항</h3>
                        </div>
                        <div className="cont_body">
                            <section className="view_sect">
                                <div className="view_header">
                                    <h4 className="title">{data.title}</h4>
                                </div>
                                <div className="view_body">
                                    <div className="question">
                                        <div className="title">
                                            <p>
                                                <span className="date">{moment(data.createdtime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}</span> | 작성자 :{" "}
                                                <span className="name">{data.compnm}</span>
                                            </p>
                                        </div>
                                        <div className="desc" dangerouslySetInnerHTML={{ __html: data.contents }}></div>
                                    </div>
                                </div>
                                <div className="btn_area">
                                    <div className="area_item">
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={() => {
                                                //홈에서도 호출되므로 goBack하지 않음
                                                this.props.history.replace(pathConstant.PATH_CUSTOMERSERVICE_NOTICE);
                                            }}
                                        >
                                            목록
                                        </button>
                                    </div>
                                    <div className="area_item">
                                        <button type="button" className="btn" onClick={this._handleOpenWriteNotice}>
                                            수정
                                        </button>
                                        <button type="button" className="btn" onClick={this._handleRemove}>
                                            삭제
                                        </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <NoticeWriteModal {...this.props} open={writeNoticeOpen} data={data} onClose={this._handleCloseWriteNotice} />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            removeBulletin,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(NoticeDetail);
export { connected as NoticeDetail };
