const baseUrl = "/service-admin";
export const pathConstant = {
    PATH_LOGIN: `/login`,
    PATH_HOME: `${baseUrl}`,
    // 고객지원
    PATH_CUSTOMERSERVICEE: `${baseUrl}/customerservice`,
    PATH_CUSTOMERSERVICE_NOTICE: `${baseUrl}/customerservice/notice`,
    PATH_CUSTOMERSERVICE_NOTICE_DETAIL: `${baseUrl}/customerservice/notice/detail`,
    PATH_CUSTOMERSERVICE_QNA: `${baseUrl}/customerservice/qna`,
    PATH_CUSTOMERSERVICE_QNA_DETAIL: `${baseUrl}/customerservice/qna/detail`,
    PATH_CUSTOMERSERVICE_FAQ: `${baseUrl}/customerservice/faq`,
    PATH_CUSTOMERSERVICE_FAQ_DETAIL: `${baseUrl}/customerservice/faq/detail`,
    // 테넌트관리
    PATH_TENANT_MANAGEMENT: `${baseUrl}/tenant-management`,
    PATH_TENANT_MANAGEMENT_APPROVAL: `${baseUrl}/tenant-management/approval`,
    PATH_TENANT_MANAGEMENT_PAYMENT: `${baseUrl}/tenant-management/payment`,
    PATH_TENANT_MANAGEMENT_LOGIN_STATISTICS: `${baseUrl}/tenant-management/login/statistics`,
    PATH_TENANT_MANAGEMENT_LOGIN_HIST: `${baseUrl}/tenant-management/login/hist`,
    PATH_TENANT_MANAGEMENT_PAYMENT_LOGS: `${baseUrl}/tenant-management/payment/logs`,
    PATH_TENANT_MANAGEMENT_GATEWAY: `${baseUrl}/tenant-management/gateway`
};
