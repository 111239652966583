import { FETCH_BULLETIN_LIST_REQUEST, FETCH_BULLETIN_LIST_SUCCESS, FETCH_BULLETIN_LIST_ERROR } from "_actions";

const initialState = {
    pending: false,
    bulletinListData: null,
    error: null
};

// 서비스 신청
export function bulletin(state = initialState, action) {
    switch (action.type) {
        case FETCH_BULLETIN_LIST_REQUEST:
            return {
                ...state,
                pending: true
            };
        case FETCH_BULLETIN_LIST_SUCCESS:
            return {
                ...state,
                pending: false,
                bulletinListData: action.data
            };
        case FETCH_BULLETIN_LIST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        default:
            return state;
    }
}
