import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { openAlertDialog } from "_actions";
import { fetchTenantDetail, setTenantApproval } from "_services";
import moment from "moment";

class ApprovalTenantDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tenantDetail: ""
        };

        this._handleChange = this._handleChange.bind(this);
        this._handleSave = this._handleSave.bind(this);
        this._handleOnClose = this._handleOnClose.bind(this);
    }

    _handleSave(tenantid) {
        this.props.setTenantApproval({ tenantid: tenantid }, (error, result) => {
            this.props.dispatch(openAlertDialog("가입신청이 완료되었습니다."));
            this._handleOnClose();
        });
    }

    _handleOnClose() {
        this.props.onClose();
    }

    _handleChange(e) {
        const { id, value } = e.target;
        this.setState({ ...this.state, [id]: value });
    }

    componentDidMount() {
        this.props.fetchTenantDetail({ tenantid: this.props.tenantid }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    tenantDetail: result
                });
            }
        });
    }

    render() {
        const { tenantDetail } = this.state;
        return (
            <>
                {tenantDetail && (
                    <Modal onShow={this._handleShow} show={this.props.open} onHide={this.props.onClose} backdrop="static" dialogClassName="modal-dialog-centered" size="lg">
                        <Modal.Header>
                            <h5 className="modal-title">서류 검토</h5>
                            <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-5">
                                    <form className="form_area">
                                        <div className="form-group">
                                            <label>ID</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.tenantid}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>회사명</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.compnm}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>사업자등록번호</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.brnumber}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>주소</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.address}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>관리자명</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.adminnm}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>전화번호</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.phone}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>이메일</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.email}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>가입일시</label>
                                            <input type="text" className="form-control" readOnly value={moment(tenantDetail.createdtime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}></input>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>사업자등록증</label>
                                        <img className="row" src={tenantDetail.brlicense_download_link} alt="사업자등록증"></img>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="btn_area">
                                {tenantDetail.status === "1" && (
                                    <button type="submit" className="btn btn_black" onClick={() => this._handleSave(tenantDetail.tenantid)}>
                                        가입 승인
                                    </button>
                                )}
                                <button type="button" className="btn" onClick={this.props.onClose}>
                                    닫기
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTenantDetail,
            setTenantApproval,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(ApprovalTenantDetail);
export { connected as ApprovalTenantDetail };
