import { LOADING_START, LOADING_STOP } from "_actions";

export function loading(state = {}, action) {
    switch (action.type) {
        case LOADING_START:
            return {
                isShow: true
            };
        case LOADING_STOP:
            return {
                isShow: false
            };
        default:
            return state;
    }
}
