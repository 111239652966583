/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { pathConstant } from "_constants";

export class TenantManagementLnbView extends Component {
    constructor(props) {
        super(props);

        this._handleMenuClick = this._handleMenuClick.bind(this);
    }
    _handleMenuClick(e, pathname) {
        e.preventDefault();

        this.props.history.push(pathname);
    }
    render() {
        const { pathname } = this.props.location;
        const waitCount = this.props.waitCount;
        document.title = "테넌트관리 | 아이브웍스";

        const Management_login = [pathConstant.PATH_TENANT_MANAGEMENT_LOGIN_STATISTICS, pathConstant.PATH_TENANT_MANAGEMENT_LOGIN_HIST];
        const Management_payment = [pathConstant.PATH_TENANT_MANAGEMENT_PAYMENT, pathConstant.PATH_TENANT_MANAGEMENT_PAYMENT_LOGS];

        return (
            <ul className="lnb">
                <li className={pathname === pathConstant.PATH_TENANT_MANAGEMENT ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_TENANT_MANAGEMENT);
                        }}
                    >
                        <span>{"테넌트관리"}</span>
                    </a>
                </li>
                <li className={Management_login.includes(pathname) ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_TENANT_MANAGEMENT_LOGIN_STATISTICS);
                        }}
                    >
                        <span>{"접속기록관리"}</span>
                    </a>
                </li>
                <li className={pathname === pathConstant.PATH_TENANT_MANAGEMENT_APPROVAL ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_TENANT_MANAGEMENT_APPROVAL);
                        }}
                    >
                        <span>{"승인대기(" + waitCount + ")"}</span>
                    </a>
                </li>
                <li className={Management_payment.includes(pathname) ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_TENANT_MANAGEMENT_PAYMENT);
                        }}
                    >
                        <span>{"결제관리"}</span>
                    </a>
                </li>
                <li className={pathname === pathConstant.PATH_TENANT_MANAGEMENT_GATEWAY ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_TENANT_MANAGEMENT_GATEWAY);
                        }}
                    >
                        <span>{"게이트웨이관리"}</span>
                    </a>
                </li>
            </ul>
        );
    }
}
