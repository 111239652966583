import _ from "lodash";
import moment from "moment";
export const commonUtils = {
    isValidNumber,
    isValidEmail,
    numberWithCommas,
    hasWhiteSpace,
    tenentStatus,
    dataPaging,
    paymentStatus,
    getDateString,
    getDateTimeString,
    getPaymentStatusString,
    signupChannel
};

function isValidNumber(value) {
    return window.$.isNumeric(value);
}

function isValidEmail(value) {
    var reg = new RegExp("^[\\w\\-]+(\\.[\\w\\-_]+)*@[\\w\\-]+(\\.[\\w\\-]+)*(\\.[a-zA-Z]{2,3})$", "gi");
    if (reg.test(value)) {
        return true;
    }
    return false;
}

function numberWithCommas(x) {
    try {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
        return x;
    }
}

function hasWhiteSpace(s) {
    return /\s/g.test(s);
}

function tenentStatus(tenant) {
    if (tenant.status === "0") {
        return "사전가입";
    } else if (tenant.status === "2") {
        return "서비스 미신청";
    } else if (tenant.status === "3") {
        if (tenant.expiredate && tenant.expiredate >= moment().format("YYYY-MM-DD")) {
            return "서비스 이용";
        } else {
            return "서비스 만료";
        }
    } else {
        return "";
    }
}

function getPaymentStatusString(status) {
    if (status === "S") {
        return "성공";
    } else if (status === "F") {
        return "실패";
    } else {
        return status;
    }
}

function getDateString(date) {
    if (!date) {
        return "";
    } else {
        try {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
        } catch (e) {
            return "";
        }
    }
}

function getDateTimeString(date) {
    if (!date) {
        return "";
    } else {
        try {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
        } catch (e) {
            return "";
        }
    }
}

function paymentStatus(tenant) {
    if (!tenant) {
        return "";
    }

    if (["0", "1", "2"].includes(tenant.status)) {
        return "";
    } else if (tenant.status === "3") {
        if (tenant.trialenddate >= moment().format("YYYY-MM-DD")) return "무료체험";
        else if (tenant.expiredate && tenant.expiredate >= moment().format("YYYY-MM-DD")) {
            return "정상결제";
        } else if (tenant.cardinfo) {
            return "미결제";
        } else {
            return "카드미등록";
        }
    } else {
        return "";
    }
}

function dataPaging(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize; // 자를 배열의 시작점

    return _(items)
        .slice(startIndex) // 시작점부터 배열을 자르되
        .take(pageSize) // pageSize만큼의 배열을 취함
        .value(); // lodash wrapper 객체를 regular 배열로 변환
}

function signupChannel(value) {
    if (!value) return "미등록";

    if (value === "R") {
        return "일반";
    } else if (value === "V") {
        return "바우처";
    } else {
        return "";
    }
}