/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TenantManagementLnbView, TenantDetail } from "_containers";
import { fetchTenantList, fetchRequestPayment } from "_services";
import { pathConstant } from "_constants";
import { commonUtils } from "_helpers";
import MaterialReactTable from 'material-react-table';

class PaymentManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alltenants: [],
            tenants: [],
            openTenantDetail: false,
            selectedTenantid: "",
            rowCount: 10,
            page: 1,
            waitCount: 0
        };

        this._handlePageChange = this._handlePageChange.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handlePaymentLogs = this._handlePaymentLogs.bind(this);
        this._requestPayment = this._requestPayment.bind(this);
    }
    _requestPayment(tenant) {
        this.props.fetchRequestPayment({ tenantid: tenant.tenantid }, (error, result) => {
            this._loadData();
        });
    }
    _handleClose() {
        this.setState({
            ...this.state,
            openTenantDetail: false,
            selectedTenantid: ""
        });

        // this._loadData();
    }

    _handlePaymentLogs(item) {
        item.waitCount = this.state.waitCount;
        this.props.history.push({ pathname: pathConstant.PATH_TENANT_MANAGEMENT_PAYMENT_LOGS, state: { data: item } });
    }

    componentDidMount() {
        this._loadData();
    }

    _loadData() {
        this.props.fetchTenantList({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    alltenants: result,
                    tenants: result.filter((item) => item.payment_status != null),
                    waitCount: result.filter((item) => item.status === "1").length
                });
            }
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber
        });
    }

    render() {
        const columns = [
            {
                header: "회사명", accessorKey: "compnm", size: 110
            },
            {
                header: "사용인원", accessorKey: "usercount", size: 50,
                accessorFn: (item) => item.usercount + "명",
            },
            {
                header: "월결제금액", accessorKey: "usagefee", size: 80,
                accessorFn: (item) => commonUtils.numberWithCommas(item.usagefee + item.usagevat) + "원",
            },
            // {
            //     header: "가입일", accessorKey: "createdtime", size: 90,
            //     accessorFn: (item) => commonUtils.getDateString(item.createdtime),
            // },
            {
                header: "무료체험기간", accessorKey: "trialenddate", size: 90,
                accessorFn: (item) => commonUtils.getDateString(item.trialenddate),
            },
            {
                header: "마지막결제요청일시", accessorKey: "last_payment_datetime", size: 140,
                accessorFn: (item) => commonUtils.getDateTimeString(item.last_payment_datetime),
            },
            {
                header: "결제상태", accessorKey: "payment_status", size: 50,
                accessorFn: (item) => commonUtils.getPaymentStatusString(item.payment_status),
            },
            {
                header: "요청", accessorKey: "request", size: 60,
                accessorFn: (item) => item.payment_status === "F" && (
                    <button
                        type="button"
                        className="btn"
                        style={{ minWidth: "50px", height: "30px" }}
                        onClick={(event) => {
                            event.stopPropagation();
                            this._requestPayment(item);
                        }}
                    >
                        결제요청
                    </button>
                )
            },
        ]
        return (
            <>
                <div className="content">
                    <div className="inner">
                        <TenantManagementLnbView {...this.props} waitCount={this.state.waitCount} />

                        <div className="main_cont">
                            <div className="cont_header">
                                <h3 className="title">결제관리</h3>
                            </div>
                            <div className="cont_body">
                                <section className="tab_sect">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="notiAll" aria-labelledby="notiAll-tab">
                                            <div className="table_area">
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={this.state.tenants || []}
                                                    initialState={{ showGlobalFilter: true }}
                                                    localization={{
                                                        noRecordsToDisplay: "데이터가 없습니다.",
                                                        noResultsFound: "데이터를 찾을 수 없습니다.",
                                                    }}
                                                    muiTablePaginationProps={{
                                                        SelectProps: { native: false },
                                                        labelRowsPerPage: '보기',
                                                        labelDisplayedRows: function defaultLabelDisplayedRows({ page }) { return `${page + 1} 페이지`; }
                                                    }}
                                                    muiSearchTextFieldProps={{
                                                        placeholder: '검색',
                                                        sx: { minWidth: '18rem' },
                                                        variant: 'outlined',
                                                    }}
                                                    muiTableBodyRowProps={({ row }) => ({
                                                        onClick: () => { this._handlePaymentLogs(row.original) },
                                                        sx: { cursor: 'pointer' },
                                                    })}
                                                    enableColumnFilters={false}
                                                    enableDensityToggle={false}
                                                    enableHiding={false}
                                                    enableSorting={true}
                                                    enableFullScreenToggle={false}
                                                    enableGlobalFilter={true}
                                                    enableFilters={true}
                                                    enableBottomToolbar={true}
                                                    enableTableHead={true}
                                                    enableTableFooter={true}
                                                    enableToolbarInternalActions={true}
                                                    enableTopToolbar={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.openTenantDetail && <TenantDetail tenantid={this.state.selectedTenantid} open={this.state.openTenantDetail} onClose={this._handleClose} />}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTenantList,
            fetchRequestPayment,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(PaymentManagement);
export { connected as PaymentManagement };
