export const FETCH_TENANT_LIST_REQUEST = "FETCH_TENANT_LIST_REQUEST";
export const FETCH_TENANT_LIST_ERROR = "FETCH_TENANT_LIST_ERROR";
export const FETCH_TENANT_LIST_SUCCESS = "FETCH_TENANT_LIST_SUCCESS";
export const FETCH_TENANT_DETAIL_REQUEST = "FETCH_TENANT_DETAIL_REQUEST";
export const FETCH_TENANT_DETAIL_ERROR = "FETCH_TENANT_DETAIL_ERROR";
export const FETCH_TENANT_DETAIL_SUCCESS = "FETCH_TENANT_DETAIL_SUCCESS";
export const FETCH_TENANT_APPROVAL_REQUEST = "FETCH_TENANT_APPROVAL_REQUEST";
export const FETCH_TENANT_APPROVAL_ERROR = "FETCH_TENANT_APPROVAL_ERROR";
export const FETCH_TENANT_APPROVAL_SUCCESS = "FETCH_TENANT_APPROVAL_SUCCESS";

export function fetchTenantListRequest() {
    return { type: FETCH_TENANT_LIST_REQUEST };
}
export function fetchTenantListError() {
    return { type: FETCH_TENANT_LIST_ERROR };
}
export function fetchTenantListSuccess() {
    return { type: FETCH_TENANT_LIST_SUCCESS };
}

export function fetchTenantDetailRequest() {
    return { type: FETCH_TENANT_DETAIL_REQUEST };
}
export function fetchTenantDetailError() {
    return { type: FETCH_TENANT_DETAIL_ERROR };
}
export function fetchTenantDetailSuccess() {
    return { type: FETCH_TENANT_DETAIL_SUCCESS };
}

export function fetchTenantApprovalRequest() {
    return { type: FETCH_TENANT_APPROVAL_REQUEST };
}
export function fetchTenantApprovalError() {
    return { type: FETCH_TENANT_APPROVAL_ERROR };
}
export function fetchTenantApprovalSuccess() {
    return { type: FETCH_TENANT_APPROVAL_SUCCESS };
}
