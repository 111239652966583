/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";

export class FloatingBar extends Component {
    render() {
        return (
            <div className="floating_bar">
                <a href="" className="top">
                    <span className="ico"></span>
                </a>
            </div>
        );
    }
}
