/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TenantManagementLnbView, TenantDetail } from "_containers";
import { fetchTenantList, getLocalSession } from "_services";
import moment from "moment";
import { commonUtils, requestParam } from "_helpers";
import MaterialReactTable from "material-react-table";

class TenantManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alltenants: [],
            tenants: [],
            openTenantDetail: false,
            selectedTenantid: "",
            selectedCompnm: "",
            status: "",
            signup_channel: "",
            rowCount: 10,
            page: 1,
            waitCount: 0
        };

        this._loadData = this._loadData.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handlePageChange = this._handlePageChange.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleTenantDetail = this._handleTenantDetail.bind(this);
        this._openTenantAdminPortal = this._openTenantAdminPortal.bind(this);
    }
    _openTenantAdminPortal(tenant) {
        let params = {
            token: getLocalSession().idToken,
            tenantid: tenant.tenantid,
            tenanturl: tenant.tenanturl,
            email: tenant.email
        };
        let url = `${process.env.REACT_APP_PORTALURL}/service-linkage/admin-portal?${requestParam(params)}`;
        window.open(url, "_blank");
    }
    _handleClose() {
        this.setState({
            ...this.state,
            openTenantDetail: false,
            selectedTenantid: "",
            selectedCompnm: ""
        });

        // this._loadData();
    }

    _handleTenantDetail(row) {
        this.setState({
            ...this.state,
            openTenantDetail: true,
            selectedTenantid: row.tenantid,
            selectedCompnm: row.compnm
        });
    }

    componentDidMount() {
        this._loadData();
    }

    _loadData() {
        this.props.fetchTenantList({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    alltenants: result,
                    tenants: result,
                    waitCount: result.filter((item) => item.status === "1").length
                });
            }
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber
        });
    }

    _handleChannelChange(e) {
        const { value } = e.target;
        let data = this.state.alltenants;

        if (value) data = data.filter((data) => data.signup_channel === value);
        data = this._filterStatus(data, this.state.status);

        this.setState({
            ...this.state,
            signup_channel: value,
            tenants: data
        });
    }

    _handleChange(e) {
        const { value } = e.target;
        let data = this.state.alltenants;

        data = this.state.signup_channel ? data.filter((data) => data.signup_channel === this.state.signup_channel) : data;
        data = this._filterStatus(data, value);

        this.setState({
            ...this.state,
            status: value,
            tenants: data
        });
    }

    _filterStatus(data, value) {
        let result = data;

        if (value === "3") {
            result = result.filter((item) => item.status === "3" && item.expiredate && item.expiredate >= moment().format("YYYY-MM-DD"));
        } else if (value === "4") {
            result = result.filter((item) => item.status === "3" && (!item.expiredate || item.expiredate < moment().format("YYYY-MM-DD")));
        } else {
            result = value ? result.filter((item) => item.status === value) : data;
        }
        return result;
    }

    render() {
        const columns = [
            {
                header: "회사명",
                accessorKey: "compnm",
                size: 150
            },
            {
                header: "가입경로",
                accessorKey: "signup_channel",
                size: 80,
                accessorFn: (rowData) => commonUtils.signupChannel(rowData.signup_channel)
            },
            {
                header: "관리자명",
                accessorKey: "adminnm",
                size: 100
            },
            {
                header: "가입일",
                accessorKey: "createdtime",
                size: 90,
                accessorFn: (item) => moment(item.createdtime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
            },
            {
                header: "사용자수",
                accessorKey: "regusercount",
                size: 80,
                muiTableBodyCellProps: {
                    align: "center"
                }
            },
            {
                header: "상태",
                size: 100,
                filterFn: "equals",
                filterSelectOptions: [
                    { text: "Male", value: "Male" },
                    { text: "Female", value: "Female" },
                    { text: "Other", value: "Other" }
                ],
                filterVariant: "select",
                Cell: ({ renderedCellValue, row }) => (
                    <span
                        style={
                            commonUtils.tenentStatus(row.original) === "서비스 이용"
                                ? {
                                      cursor: "pointer",
                                      color: "#00cae8",
                                      fontWeight: 600
                                  }
                                : {}
                        }
                    >
                        {renderedCellValue}
                    </span>
                ),
                accessorFn: (rowData) => {
                    return commonUtils.tenentStatus(rowData);
                }
            },
            {
                header: "결제상태",
                size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <span
                        style={
                            commonUtils.paymentStatus(row.original) === "정상결제"
                                ? {
                                      color: "#00cae8",
                                      fontWeight: 600
                                  }
                                : {}
                        }
                    >
                        {renderedCellValue}
                    </span>
                ),
                accessorFn: (rowData) => {
                    return commonUtils.paymentStatus(rowData);
                }
            },
            {
                header: "관리콘솔",
                accessorKey: "managementConsole",
                size: 50,
                accessorFn: (rowData) =>
                    rowData.status === "3" ? (
                        <button
                            type="button"
                            className="btn"
                            style={{ minWidth: "50px", height: "30px" }}
                            onClick={(event) => {
                                event.stopPropagation();
                                this._openTenantAdminPortal(rowData);
                            }}
                        >
                            접속
                        </button>
                    ) : (
                        "-"
                    )
            }
        ];

        function accountAll(tenants) {
            const generalJoin = tenants.filter((data) => data.signup_channel === "R");
            const voucherJoin = tenants.filter((data) => data.signup_channel === "V");
            const payCustomerCount = tenants.filter((data) => data.status === "3" && data.payment_status === "S" && (!data.expiredate || data.expiredate >= moment().format("YYYY-MM-DD")));
            return (
                <h2 className="total-info-header">
                    <span>전체 가입자 수: </span> {tenants.length}
                    <span>일반 가입자: </span> {generalJoin.length}
                    <span>바우처 가입자: </span> {voucherJoin.length}
                    <span>유료 고객: </span> {payCustomerCount.length}
                </h2>
            );
        }

        return (
            <>
                <div className="content">
                    <div className="inner">
                        <TenantManagementLnbView {...this.props} waitCount={this.state.waitCount} />

                        <div className="main_cont">
                            <div className="cont_header">
                                <h3 className="title">테넌트관리</h3>
                            </div>
                            <div className="cont_body">
                                <section className="tab_sect">
                                    <div className="view_header tenant_mgmt_header">{accountAll(this.state.tenants)}</div>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="notiAll" aria-labelledby="notiAll-tab">
                                            <div className="table_area">
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={this.state.tenants || []}
                                                    initialState={{ pagination: { pageSize: 50, pageIndex: 0 }, showGlobalFilter: true }}
                                                    localization={{
                                                        noRecordsToDisplay: "데이터가 없습니다.",
                                                        noResultsFound: "데이터를 찾을 수 없습니다."
                                                    }}
                                                    muiTablePaginationProps={{
                                                        SelectProps: { native: false },
                                                        labelRowsPerPage: "보기",
                                                        labelDisplayedRows: function defaultLabelDisplayedRows({ page }) {
                                                            return `${page + 1} 페이지`;
                                                        }
                                                    }}
                                                    muiSearchTextFieldProps={{
                                                        placeholder: "검색",
                                                        sx: { minWidth: "18rem" },
                                                        variant: "outlined"
                                                    }}
                                                    muiTableBodyRowProps={({ row }) => ({
                                                        onClick: () => {
                                                            this._handleTenantDetail(row.original);
                                                        },
                                                        sx: { cursor: "pointer" }
                                                    })}
                                                    enableColumnFilters={false}
                                                    enableDensityToggle={false}
                                                    enableHiding={false}
                                                    enableSorting={true}
                                                    enableFullScreenToggle={false}
                                                    enableGlobalFilter={true}
                                                    enableFilters={true}
                                                    enableBottomToolbar={true}
                                                    enableTableHead={true}
                                                    enableTableFooter={true}
                                                    enableToolbarInternalActions={true}
                                                    enableTopToolbar={true}
                                                    renderTopToolbarCustomActions={() => (
                                                        <ul className="nav" id="pills-tab">
                                                            <select style={{ width: "150px" }} className="form-select rm5" id="signup" onChange={(e) => this._handleChannelChange(e)} name="channel">
                                                                <option value=""> 전체 </option>
                                                                <option value="V"> 바우처 고객 </option>
                                                                <option value="R"> 일반 고객 </option>
                                                            </select>
                                                            <select style={{ width: "150px" }} className="form-select" id="category" onChange={(e) => this._handleChange(e)} name="status">
                                                                <option value=""> 전체 </option>
                                                                <option value="0"> 사전가입 </option>
                                                                <option value="2"> 서비스미신청 </option>
                                                                <option value="4"> 서비스만료 </option>
                                                                <option value="3"> 서비스이용 </option>
                                                            </select>
                                                        </ul>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.openTenantDetail && (
                    <TenantDetail tenantid={this.state.selectedTenantid} compnm={this.state.selectedCompnm} refresh={this._loadData} open={this.state.openTenantDetail} onClose={this._handleClose} />
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTenantList,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(TenantManagement);
export { connected as TenantManagement };
