export const FETCH_BULLETIN_LIST_REQUEST = "FETCH_BULLETIN_LIST_REQUEST";
export const FETCH_BULLETIN_LIST_SUCCESS = "FETCH_BULLETIN_LIST_SUCCESS";
export const FETCH_BULLETIN_LIST_ERROR = "FETCH_BULLETIN_LIST_ERROR";
export const ADD_BULLETIN_REQUEST = "ADD_BULLETIN_REQUEST";
export const ADD_BULLETIN_SUCCESS = "ADD_BULLETIN_SUCCESS";
export const ADD_BULLETIN_ERROR = "ADD_BULLETIN_ERROR";
export const MODIFY_BULLETIN_REQUEST = "MODIFY_BULLETIN_REQUEST";
export const MODIFY_BULLETIN_SUCCESS = "MODIFY_BULLETIN_SUCCESS";
export const MODIFY_BULLETIN_ERROR = "MODIFY_BULLETIN_ERROR";
export const REMOVE_BULLETIN_REQUEST = "REMOVE_BULLETIN_REQUEST";
export const REMOVE_BULLETIN_SUCCESS = "REMOVE_BULLETIN_SUCCESS";
export const REMOVE_BULLETIN_ERROR = "REMOVE_BULLETIN_ERROR";

export function fetchBulletinListRequest() {
    return { type: FETCH_BULLETIN_LIST_REQUEST };
}
export function fetchBulletinListSuccess(data) {
    return { type: FETCH_BULLETIN_LIST_SUCCESS, data };
}
export function fetchBulletinListError(error) {
    return { type: FETCH_BULLETIN_LIST_ERROR, error };
}
export function addBulletinRequest() {
    return { type: ADD_BULLETIN_REQUEST };
}
export function addBulletinSuccess(data) {
    return { type: ADD_BULLETIN_SUCCESS, data };
}
export function addBulletinError(error) {
    return { type: ADD_BULLETIN_ERROR, error };
}
export function modifyBulletinRequest() {
    return { type: MODIFY_BULLETIN_REQUEST };
}
export function modifyBulletinSuccess(data) {
    return { type: MODIFY_BULLETIN_SUCCESS, data };
}
export function modifyBulletinError(error) {
    return { type: MODIFY_BULLETIN_ERROR, error };
}
export function removeBulletinRequest() {
    return { type: REMOVE_BULLETIN_REQUEST };
}
export function removeBulletinSuccess(data) {
    return { type: REMOVE_BULLETIN_SUCCESS, data };
}
export function removeBulletinError(error) {
    return { type: REMOVE_BULLETIN_ERROR, error };
}
