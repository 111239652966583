/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TenantManagementLnbView } from "_containers";
import { fetchTenantPaymentLogs } from "_services";
import { commonUtils } from "_helpers";
import moment from "moment";
import { SendMailModal } from "./SendMailModal";
import MaterialReactTable from 'material-react-table';

class PaymentLogs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTenant: { ...props.location.state.data },
            logs: []
        };

        this._handlePageChange = this._handlePageChange.bind(this);
        this._handleCloseSendMail = this._handleCloseSendMail.bind(this);
        this._handleOpenSendMail = this._handleOpenSendMail.bind(this);
    }

    _handleOpenSendMail() {
        this.setState({
            ...this.state,
            sendMailOpen: true
        });
    }

    _handleCloseSendMail() {
        this.setState({
            ...this.state,
            sendMailOpen: false
        });

        // this._loadData();
    }

    componentDidMount() {
        this._loadData();
    }

    _loadData() {
        this.props.fetchTenantPaymentLogs({ tenantid: this.state.selectedTenant.tenantid }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    logs: result
                });
            }
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber
        });
    }

    render() {
        const columns = [
            {
                header: "대상년월", size: 130,
                accessorFn: (rowData) => moment(rowData.targetmonth, "YYYYMM").format("YYYY년 MM월"),
            },
            {
                header: "사용인원", accessorKey: "usercount", size: 80,
                accessorFn: (rowData) => rowData.usercount + "명",
            },
            {
                header: "월결제금액", accessorKey: "usagefee", size: 120,
                accessorFn: (rowData) => commonUtils.numberWithCommas(rowData.amount) + "원",
            },
            {
                header: "요청일시", accessorKey: "createdtime", size: 180,
                accessorFn: (rowData) => commonUtils.getDateTimeString(rowData.createdtime),
            },
            {
                header: "결과", accessorKey: "trialenddate", size: 80,
                accessorFn: (rowData) => commonUtils.getPaymentStatusString(rowData.status),
            },
            {
                header: "실패내용", accessorKey: "last_payment_datetime", size: 130,
                accessorFn: (rowData) => JSON.parse(rowData.result).message || "",
            }
        ]
        // 결제 내역이 없습니다.
        return (
            <>
                <div className="content">
                    <div className="inner">
                        <TenantManagementLnbView {...this.props} waitCount={this.state.selectedTenant.waitCount} />
                        <div className="main_cont">
                            <div className="cont_header">
                                <h3 className="title">{this.state.selectedTenant.compnm} 결제이력</h3>
                            </div>

                            <div className="cont_body">
                                <section className="view_sect">
                                    <div className="view_body">
                                        <MaterialReactTable
                                            columns={columns}
                                            data={this.state.logs || []}
                                            initialState={{ showGlobalFilter: true }}
                                            localization={{
                                                noRecordsToDisplay: "데이터가 없습니다.",
                                                noResultsFound: "데이터를 찾을 수 없습니다.",
                                            }}
                                            muiTablePaginationProps={{
                                                SelectProps: { native: false },
                                                labelRowsPerPage: '보기',
                                                labelDisplayedRows: function defaultLabelDisplayedRows({ page }) { return `${page + 1} 페이지`; }
                                            }}
                                            muiSearchTextFieldProps={{
                                                placeholder: '검색',
                                                sx: { minWidth: '18rem' },
                                                variant: 'outlined',
                                            }}
                                            enableColumnFilters={false}
                                            enableDensityToggle={false}
                                            enableHiding={false}
                                            enableSorting={true}
                                            enableFullScreenToggle={false}
                                            enableGlobalFilter={true}
                                            enableFilters={true}
                                            enableBottomToolbar={true}
                                            enableTableHead={true}
                                            enableTableFooter={true}
                                            enableToolbarInternalActions={true}
                                            enableTopToolbar={true}
                                        />
                                    </div>
                                    <div className="btn_area">
                                        <div className="area_item">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={() => {
                                                    this.props.history.goBack();
                                                }}
                                            >
                                                목록
                                            </button>
                                        </div>
                                        <div className="area_item">
                                            <button type="button" className="btn btn_black" onClick={this._handleOpenSendMail}>
                                                메일발송
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <SendMailModal {...this.props} open={this.state.sendMailOpen} data={this.state.selectedTenant} onClose={this._handleCloseSendMail} />
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTenantPaymentLogs,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(PaymentLogs);
export { connected as PaymentLogs };
