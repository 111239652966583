export const codeConstant = {
    CODE_SERVICE_TYPE: {
        SR: "서비스 사용 신청",
        TE: "시범 사용 종료",
        UM: "사용자 변경",
        CC: "카드 변경"
    },
    CODE_PAYMENT_TYPE: {
        C: "신용카드 (자동결제)"
    },
    CODE_CATEGORY_NOTICE: {
        "00185": "안내",
        "00186": "점검",
        "00182": "장애",
        "00184": "기타"
    },
    CODE_CATEGORY_QNA: {
        "00181": "서비스",
        "00182": "장애",
        "00183": "기술지원",
        "00184": "기타"
    },
    CODE_CATEGORY_FAQ: {
        "00181": "서비스",
        "00182": "장애",
        "00183": "기술지원",
        "00184": "기타"
    },
    CODE_BULLETIN_TYPE: {
        NOTICE: "00193",
        FAQ: "00194",
        QNA: "00195"
    }
};
