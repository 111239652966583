/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { pathConstant } from "_constants";

export class CustomerServiceLnbView extends Component {
    constructor(props) {
        super(props);

        this._handleMenuClick = this._handleMenuClick.bind(this);
    }
    _handleMenuClick(e, pathname) {
        e.preventDefault();

        this.props.history.push(pathname);
    }
    render() {
        const { pathname } = this.props.location;
        document.title = "고객지원 | 아이브웍스";
        return (
            <ul className="lnb">
                <li className={pathname === pathConstant.PATH_CUSTOMERSERVICE_NOTICE || pathname === pathConstant.PATH_CUSTOMERSERVICE_NOTICE_DETAIL ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_CUSTOMERSERVICE_NOTICE);
                        }}
                    >
                        <span>공지사항</span>
                    </a>
                </li>
                <li className={pathname === pathConstant.PATH_CUSTOMERSERVICE_QNA || pathname === pathConstant.PATH_CUSTOMERSERVICE_QNA_DETAIL ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_CUSTOMERSERVICE_QNA);
                        }}
                    >
                        <span>{"Q&A"}</span>
                    </a>
                </li>
                <li className={pathname === pathConstant.PATH_CUSTOMERSERVICE_FAQ || pathname === pathConstant.PATH_CUSTOMERSERVICE_FAQ_DETAIL ? "active" : ""}>
                    <a
                        href=""
                        onClick={(e) => {
                            this._handleMenuClick(e, pathConstant.PATH_CUSTOMERSERVICE_FAQ);
                        }}
                    >
                        <span>{"FAQ"}</span>
                    </a>
                </li>
            </ul>
        );
    }
}
