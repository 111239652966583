import { requestUrl, requestHeaders } from "_helpers";
import { loginRequest, loginSuccess, loginError } from "_actions";

// 로그인
export function login(callback = (error, result) => {}) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/login`);
        const requestOptions = {
            credentials: "same-origin",
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        dispatch(loginRequest());
        return fetch(url, requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    callback(response.status);
                } else {
                    callback();
                }
                dispatch(loginSuccess(response.data));
            })
            .catch((error) => {
                callback(error);
                dispatch(loginError(error));
            });
    };
}

export function cleanLocalSession() {
    sessionStorage.clear();
}

export function getLocalSession() {
    return JSON.parse(sessionStorage.getItem("session"));
}

export function setLocalSession(session) {
    sessionStorage.setItem("session", JSON.stringify(session));
}
