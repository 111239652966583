import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { openAlertDialog } from "_actions";
import { fetchTenantDetail, fetchDeleteTenant, adminSignupChannelChange } from "_services";
import { commonUtils, downloadExcel, requestUrl } from "_helpers";
import moment from "moment";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";

class TenantDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tenantDetail: "",
            tenantid: props.tenantid,
            compnm: props.compnm,
            searchdate: moment().format("YYYY-MM")
        };

        this._handleChange = this._handleChange.bind(this);
        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleOnDelete = this._handleOnDelete.bind(this);
        this._signupChannelChange = this._signupChannelChange.bind(this);
    }

    _handleOnClose() {
        this.props.onClose();
    }

    _handleOnDelete() {
        this.props.dispatch(
            openAlertDialog("삭제하시겠습니까?", true, "삭제", "취소", () => {
                this.props.fetchDeleteTenant({ tenantid: this.props.tenantid }, (error, result) => {
                    if (result) {
                        this.props.dispatch(
                            openAlertDialog("삭제되었습니다.", false, null, null, () => {
                                this.props.onClose();
                                window.location.reload();
                            })
                        );
                    } else {
                        this.props.dispatch(openAlertDialog("테넌트를 삭제할 수 없습니다.", false, null, null, () => {}));
                    }
                });
            })
        );
    }

    _handleChange(e) {
        const { id, value } = e.target;
        this.setState({ ...this.state, [id]: value });
    }

    _signupChannelChange(signupChannel, tenantId) {
        let params = {
            tenantid: tenantId,
            signup_channel: signupChannel
        };
        this.props.adminSignupChannelChange(params, (error, result) => {
            this.props.dispatch(openAlertDialog("가입경로가 변경되었습니다."));

            this.state.tenantDetail.signup_channel = signupChannel;
            this.setState({ signup_channel: signupChannel });
            this.props.refresh();
        });
    }

    componentDidMount() {
        this.props.fetchTenantDetail({ tenantid: this.props.tenantid }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    tenantDetail: result
                });
            }
        });
    }

    render() {
        const { tenantDetail, tenantid, compnm, searchdate } = this.state;
        let canDelete = tenantDetail.status !== "3" || (tenantDetail.outyn === "Y" && tenantDetail.outreqtime);

        function usageDownload() {
            const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants/service-usage`);
            let data = {
                tenantid: tenantid,
                compnm: compnm,
                yearmonth: searchdate.replace("-", "")
            };
            downloadExcel(url, data);
        }

        return (
            <>
                {tenantDetail && (
                    <Modal onShow={this._handleShow} show={this.props.open} onHide={this.props.onClose} backdrop="static" dialogClassName="modal-dialog-centered">
                        <Modal.Header>
                            <h5 className="modal-title">상세조회({commonUtils.paymentStatus(tenantDetail)})</h5>
                            <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <form className="form_area">
                                        <div className="form-group">
                                            <label>ID</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.tenantid}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>회사명</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.compnm}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>가입경로</label>
                                            <select
                                                className="form-select"
                                                value={tenantDetail.signup_channel || "R"}
                                                onChange={(e) => this._signupChannelChange(e.target.value, tenantDetail.tenantid)}
                                            >
                                                <option value="R"> 일반 고객 </option>
                                                <option value="V"> 바우처 고객 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>사업자등록번호</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.brnumber}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>주소</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.address}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>관리자명</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.adminnm}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>전화번호</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.phone}></input>
                                        </div>
                                        <div className="form-group">
                                            <label>이메일</label>
                                            <input type="text" className="form-control" readOnly value={tenantDetail.email}></input>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-6">
                                                <label>가입일</label>
                                                <input type="text" className="form-control" readOnly value={commonUtils.getDateString(tenantDetail.createdtime)}></input>
                                            </div>
                                            <div className="col-md-6">
                                                <label>서비스 만료일</label>
                                                <input type="text" className="form-control" readOnly value={commonUtils.getDateString(tenantDetail.expiredate) || ""}></input>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-6">
                                                <label>사용인원(명)</label>
                                                <input type="text" className="form-control" readOnly value={tenantDetail.usercount || ""}></input>
                                            </div>
                                            <div className="col-md-6">
                                                <label>월 이용금액(원)</label>
                                                <input type="text" className="form-control" readOnly value={tenantDetail.usagefee || ""}></input>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>마지막 결제일시</label>
                                            <input type="text" className="form-control" readOnly value={commonUtils.getDateTimeString(tenantDetail.last_payment_date) || ""}></input>
                                        </div>
                                        <div className="form-group">
                                            {tenantDetail.brlicense_download_link && (
                                                <a href={tenantDetail.brlicense_download_link} target="_blank" rel="noreferrer" style={{ color: "#00cae8" }}>
                                                    사업자등록증 보기
                                                </a>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>사용량 다운로드</label>
                                            <div className="usage-area">
                                                <div className="usage-text">검색일자 :</div>
                                                <div className="usage-date">
                                                    <DatePicker
                                                        id="searchTime"
                                                        name="searchTime"
                                                        className="form-control date-picker-table"
                                                        dateFormat="yyyy-MM"
                                                        showMonthYearPicker
                                                        maxDate={new Date()}
                                                        locale={ko}
                                                        value={this.state.searchdate}
                                                        onChange={(nextDate) => {
                                                            this.setState({
                                                                ...this.state,
                                                                searchdate: moment(nextDate).format("YYYY-MM")
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="usage-button">
                                                    <button
                                                        type="button"
                                                        className="btn login-statis-check-btn"
                                                        onClick={() => {
                                                            usageDownload();
                                                        }}
                                                    >
                                                        다운로드
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="btn_area">
                                {canDelete && (
                                    <button type="button" className="btn btn_black" onClick={this._handleOnDelete}>
                                        삭제
                                    </button>
                                )}
                                <button type="button" className="btn" onClick={this._handleOnClose}>
                                    닫기
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTenantDetail,
            fetchDeleteTenant,
            adminSignupChannelChange,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(TenantDetail);
export { connected as TenantDetail };
