import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./loading.module.css";

class Loading extends Component {
    constructor(props) {
        super(props);

        window.fetch.dispatch = this.props.dispatch;
    }
    render() {
        return this.props.loading ? (
            <div className={styles.loading}>
                <div className={styles.main_ring}>
                    <span className={styles.mdi_ico}>account_circle</span>
                    <span className={styles.mdi_ico}>home</span>
                    <span className={styles.mdi_ico}>work</span>
                    <div className={styles.rotate_ring}></div>
                </div>
            </div>
        ) : null;
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading.isShow,
    };
}
const connected = connect(mapStateToProps)(Loading);
export { connected as Loading };
