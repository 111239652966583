import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import { codeConstant } from "_constants";
import { openAlertDialog } from "_actions";
import { addBulletin, modifyBulletin } from "_services";

class FAQWriteModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            category: "",
            contents: ""
        };

        this._handleShow = this._handleShow.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSave = this._handleSave.bind(this);
    }
    _handleShow() {
        if (this.props.data) {
            const { data } = this.props;
            this.setState({
                ...this.state,
                title: data.title,
                category: data.bltcategory,
                contents: data.contents.replaceAll("<br/>", "\n")
            });
        } else {
            this.setState({
                ...this.state,
                title: "",
                category: "",
                contents: ""
            });
        }
    }
    _handleChange(e) {
        const { id, value } = e.target;
        this.setState({ ...this.state, [id]: value });
    }
    _handleSave() {
        const { data } = this.props;

        if (data) {
            this._modifyNotice();
        } else {
            this._addNotice();
        }
    }
    _addNotice() {
        const { title, category, contents } = this.state;

        const params = { blttype: codeConstant.CODE_BULLETIN_TYPE.FAQ, bltcategory: category, title, contents };
        this.props.addBulletin(params, (error, data) => {
            if (!error) {
                this.props.dispatch(
                    openAlertDialog("등록되었습니다.", false, null, null, () => {
                        this.props.onClose();
                        window.location.reload();
                    })
                );
            }
        });
    }
    _modifyNotice() {
        const { title, category, contents } = this.state;
        const { data } = this.props;

        const params = { bltid: data.bltid, bltcategory: category, title, contents };
        this.props.modifyBulletin(params, (error, data) => {
            if (!error) {
                this.props.dispatch(
                    openAlertDialog("수정되었습니다.", false, null, null, () => {
                        this.props.onClose();
                        this.props.history.goBack();
                    })
                );
            }
        });
    }
    render() {
        const { title, category, contents } = this.state;
        return (
            <Modal onShow={this._handleShow} show={this.props.open} onHide={this.props.onClose} backdrop="static" dialogClassName="modal-dialog-centered">
                <Modal.Header>
                    <h5 className="modal-title">FAQ</h5>
                    <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                </Modal.Header>
                <Modal.Body>
                    <form className="form_area">
                        <div className="form-group">
                            <label className="asterisk">유형</label>
                            <select className="form-select" id="category" onChange={this._handleChange} value={category}>
                                <option value="">유형을 선택해 주세요.</option>
                                {Object.keys(codeConstant.CODE_CATEGORY_FAQ).map((key, index) => (
                                    <option key={index} value={key}>
                                        {codeConstant.CODE_CATEGORY_FAQ[key]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="asterisk">제목</label>
                            <input type="text" id="title" className="form-control" placeholder="제목을 입력해 주세요." value={title} onChange={this._handleChange} />
                        </div>

                        <div className="form-group">
                            <label className="asterisk">내용</label>
                            <textarea id="contents" className="form-control" placeholder="내용을 입력해 주세요." value={contents} onChange={this._handleChange}></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn_area">
                        <button type="submit" className="btn btn_black" onClick={this._handleSave} disabled={title.trim() === "" || category.trim() === "" || contents.trim() === ""}>
                            저장
                        </button>
                        <button type="button" className="btn" onClick={this.props.onClose}>
                            취소
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addBulletin,
            modifyBulletin,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(FAQWriteModal);
export { connected as FAQWriteModal };
