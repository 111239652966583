import { LOGIN_SUCCESS, LOGIN_ERROR } from "_actions";

export function login(state = {}, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedin: true
            };
        case LOGIN_ERROR:
            return {
                ...state,
                isLoggedin: false
            };
        default:
            return state;
    }
}
