/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { CustomerServiceLnbView } from "_containers";
import { pathConstant, codeConstant } from "_constants";
import { Pagination } from "_components";
import { fetchBulletinList } from "_services";

class QnA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionOpen: false,
            category: "",
            keyword: "",
            rowCount: 10,
            page: 1
        };

        this._handleCategory = this._handleCategory.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSearch = this._handleSearch.bind(this);
        this._handleDetail = this._handleDetail.bind(this);
        this._handlePageMove = this._handlePageMove.bind(this);
    }
    componentDidMount() {
        const { category, page } = this.state;
        this._loadData(category, page);
    }
    static getDerivedStateFromProps(props, state) {
        if (props.bulletinListData && props.bulletinListData.pagination.pageno !== state.page) {
            return {
                page: props.bulletinListData.pagination.pageno
            };
        }
        return null;
    }
    _loadData(category, page) {
        const { rowCount, keyword } = this.state;
        const params = {
            blttype: codeConstant.CODE_BULLETIN_TYPE.QNA,
            bltcategory: category,
            include_contents: true,
            include_replies: true,
            keyword,
            pageno: page,
            pagesize: rowCount
        };
        this.props.fetchBulletinList(params);
    }
    _handlePageMove(page) {
        const { category } = this.state;
        this._loadData(category, page);
    }
    _handleCategory(category) {
        this.setState({
            ...this.state,
            category
        });

        const { page } = this.state;
        this._loadData(category, page);
    }
    _handleChange(e) {
        const { id, value } = e.target;
        this.setState({ ...this.state, [id]: value });
    }
    _handleSearch(e) {
        e.preventDefault();

        const { category, page } = this.state;
        this._loadData(category, page);
    }
    _handleDetail(e, item) {
        e.preventDefault();
        this.props.history.push({ pathname: pathConstant.PATH_CUSTOMERSERVICE_QNA_DETAIL, state: { data: item } });
    }

    render() {
        const { category, keyword, rowCount, page } = this.state;
        const { bulletinListData } = this.props;
        const dataList = bulletinListData ? bulletinListData.data : [];
        const pagination = bulletinListData ? bulletinListData.pagination : null;
        return (
            <div className="content">
                <div className="inner">
                    <CustomerServiceLnbView {...this.props} />

                    <div className="main_cont">
                        <div className="cont_header">
                            <h3 className="title">{"Q&A"}</h3>
                        </div>
                        <div className="cont_body">
                            <section className="tab_sect">
                                <form onSubmit={this._handleSearch} className="input-group input-group-search">
                                    <input type="search" className="form-control" placeholder="검색어를 입력하세요." id="keyword" value={keyword} onChange={this._handleChange} />
                                    <button type="submit" className="ico_btn"></button>
                                </form>
                                <ul className="nav nav-pills" id="pills-tab">
                                    <li className="nav-item" role="presentation">
                                        <button className={"nav-link " + (category === "" ? "active" : "")} type="button" onClick={() => this._handleCategory("")}>
                                            전체
                                        </button>
                                    </li>
                                    {Object.keys(codeConstant.CODE_CATEGORY_QNA).map((key, index) => (
                                        <li key={index} className="nav-item" role="presentation">
                                            <button className={"nav-link " + (category === key ? "active" : "")} type="button" onClick={() => this._handleCategory(key)}>
                                                {codeConstant.CODE_CATEGORY_QNA[key]}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="qnaAll" aria-labelledby="qnaAll-tab">
                                        <div className="table_area">
                                            <div className="table qna">
                                                <div className="table_row heading">
                                                    <div className="row_item num">No.</div>
                                                    <div className="row_item category">구분</div>
                                                    <div className="row_item title">제목</div>
                                                    <div className="row_item name">작성자</div>
                                                    <div className="row_item date">작성일</div>
                                                    <div className="row_item hits">조회수</div>
                                                </div>
                                                {dataList.length === 0 && (
                                                    <div className="table_row">
                                                        <div className="row_item none">등록된 게시글이 없습니다.</div>
                                                    </div>
                                                )}
                                                {dataList.map((item, index) => (
                                                    <div className="table_row" key={index}>
                                                        <a href="" className="row_link" onClick={(e) => this._handleDetail(e, item)}>
                                                            <div className="row_item num">{item.bltid}</div>
                                                            <div className="row_item category">{codeConstant.CODE_CATEGORY_QNA[item.bltcategory]}</div>
                                                            <div className="row_item title">
                                                                <span className={"badge " + (item.replies.length > 0 ? "done" : "wait")}>{item.replies.length > 0 ? "답변완료" : "답변대기"}</span>
                                                                <span className="ellipsis">{item.title}</span>
                                                            </div>
                                                            <div className="row_item name">{item.compnm}</div>
                                                            <div className="row_item date">{moment(item.createdtime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}</div>
                                                            <div className="row_item hits">{item.clickcnt}</div>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <Pagination totalCount={pagination ? pagination.total : 0} rowCount={rowCount} page={page} onPageMove={this._handlePageMove} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { bulletin } = state;
    return {
        bulletinListData: bulletin.bulletinListData
    };
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchBulletinList,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(QnA);
export { connected as QnA };
