import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { loadingStart, loadingStop } from "_actions";
import { pathConstant } from "_constants";
import { getLocalSession, setLocalSession } from "_services";

var firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID
};

firebase.initializeApp(firebaseConfig);

export function firebaseSignUp(email, password) {
    console.log("firebaseSignUp request");
    window.fetch.dispatch(loadingStart());
    return firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
            console.log("firebaseSignUp success");
            window.fetch.dispatch(loadingStop());
            return data;
        })
        .catch((error) => {
            console.log("firebaseSignUp error=" + JSON.stringify(error));
            window.fetch.dispatch(loadingStop());
            return Promise.reject(error);
        });
}

export function firebaseSignIn(email, password) {
    console.log("firebaseSignIn request");
    window.fetch.dispatch(loadingStart());
    return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((data) => {
            // console.log("firebaseSignIn success user=" + JSON.stringify(data));
            window.fetch.dispatch(loadingStop());
            return data;
        })
        .catch((error) => {
            // console.log("firebaseSignIn error=" + JSON.stringify(error));
            window.fetch.dispatch(loadingStop());
            return Promise.reject(error);
        });
}

export function firebaseSendEmailVerification() {
    console.log("fireBaseSendEmailVerification request");
    window.fetch.dispatch(loadingStart());
    return firebase
        .auth()
        .currentUser.sendEmailVerification()
        .then((data) => {
            console.log("firebaseSendEmailVerification success");
            window.fetch.dispatch(loadingStop());
            return data;
        })
        .catch((error) => {
            console.log("firebaseSendEmailVerification error=" + JSON.stringify(error));
            window.fetch.dispatch(loadingStop());
            return Promise.reject(error);
        });
}

export function firebaseSignOut() {
    console.log("firebaseSignOut request");
    window.fetch.dispatch(loadingStart());
    return firebase
        .auth()
        .signOut()
        .then(() => {
            console.log("firebaseSignOut success");
            window.fetch.dispatch(loadingStop());
            return;
        })
        .catch((error) => {
            console.log("firebaseSignOut error");
            window.fetch.dispatch(loadingStop());
            return Promise.reject(error);
        });
}

export function firebaseGetIdToken() {
    console.log("firebaseGetIdToken request");
    return firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((data) => {
            console.log("firebaseGetIdToken success");
            return data;
        })
        .catch((error) => {
            console.log("fireBaseGetIdToken error=" + JSON.stringify(error));
            return Promise.reject(error);
        });
}

export function firebaseRefreshToken() {
    console.log("firebaseRefreshToken request");
    return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            user.getIdToken(true)
                .then((idToken) => {
                    console.log("firebaseRefreshToken success");
                    const session = getLocalSession();
                    session.idToken = idToken;
                    setLocalSession(session);
                    window.location.reload();
                })
                .catch((error) => {
                    console.log("firebaseRefreshToken error=" + JSON.stringify(error));
                    window.location.href = pathConstant.PATH_LOGIN;
                });
        } else {
            window.location.href = pathConstant.PATH_LOGIN;
        }
    });
}
