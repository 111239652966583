import { Home, Login, Notice, NoticeDetail, QnA, QnADetail, FAQ, FAQDetail, TenantManagement, TenantManagementLoginStatistics, TenantManagementLoginHist, PaymentManagement, ApprovalManagement, PaymentLogs, GatewayManagement } from "_containers";
import { pathConstant } from "_constants";

export const routes = [
    {
        path: pathConstant.PATH_HOME,
        name: "Home",
        component: Home,
        exact: true
    },
    {
        path: pathConstant.PATH_TENANT_MANAGEMENT,
        name: "TenantManagement",
        component: TenantManagement,
        exact: true
    },
    {
        path: pathConstant.PATH_TENANT_MANAGEMENT_APPROVAL,
        name: "ApprovalManagement",
        component: ApprovalManagement,
        exact: true
    },
    {
        path: pathConstant.PATH_TENANT_MANAGEMENT_PAYMENT,
        name: "PaymentManagement",
        component: PaymentManagement,
        exact: true
    },
    {
        path: pathConstant.PATH_TENANT_MANAGEMENT_LOGIN_STATISTICS,
        name: "TenantManagementLoginStatistics",
        component: TenantManagementLoginStatistics,
        exact: true
    },
    {
        path: pathConstant.PATH_TENANT_MANAGEMENT_LOGIN_HIST,
        name: "TenantManagementLoginHist",
        component: TenantManagementLoginHist,
        exact: true
    },
    {
        path: pathConstant.PATH_TENANT_MANAGEMENT_PAYMENT_LOGS,
        name: "PaymentLogs",
        component: PaymentLogs,
        exact: true
    },
    {
        path: pathConstant.PATH_TENANT_MANAGEMENT_GATEWAY,
        name: "GatewayManagement",
        component: GatewayManagement,
        exact: true
    },
    {
        path: pathConstant.PATH_LOGIN,
        name: "Login",
        component: Login,
        exact: true
    },

    {
        path: pathConstant.PATH_CUSTOMERSERVICE_NOTICE,
        name: "Notice",
        component: Notice,
        exact: true
    },
    {
        path: pathConstant.PATH_CUSTOMERSERVICE_NOTICE_DETAIL,
        name: "NoticeDetail",
        component: NoticeDetail,
        exact: true
    },
    {
        path: pathConstant.PATH_CUSTOMERSERVICE_QNA,
        name: "QnA",
        component: QnA,
        exact: true
    },
    {
        path: pathConstant.PATH_CUSTOMERSERVICE_QNA_DETAIL,
        name: "QnADetail",
        component: QnADetail,
        exact: true
    },
    {
        path: pathConstant.PATH_CUSTOMERSERVICE_FAQ,
        name: "FAQ",
        component: FAQ,
        exact: true
    },
    {
        path: pathConstant.PATH_CUSTOMERSERVICE_FAQ_DETAIL,
        name: "FAQDetail",
        component: FAQDetail,
        exact: true
    }
];
