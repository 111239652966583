/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import logoGrayImg from "_asset/images/logo_gray.svg";

export class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="inner">
                    <div className="corp_info">
                        <div className="logo">
                            <img src={logoGrayImg} alt="아이브웍스 로고" />
                        </div>
                        <div className="info_list">
                            <ul>
                                <li>(주) 아이브랩</li>
                                <li>대표 서영주 김용우</li>
                                <li>13449 경기도 성남시 수정구 창업로 54 판교제2테크노밸리 판교기업성장센터 525, 526호</li>
                                <li>사업자등록번호 545-81-01078</li>
                                <li>통신판매업신고 제 2019-성남수정-0502 호</li>
                                <li>sales@aiblab.co.kr</li>
                                <li>070-8892-7160</li>
                            </ul>
                            <p className="copyright">Copyright © AIBLab Inc. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
