import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes } from "_helpers";
import { pathConstant } from "_constants";
import { Header, Footer, FloatingBar, ScrollToTop } from "_components";

export class AppLayout extends Component {
    render() {
        const { pathname } = this.props.location;
        const showFloatingBar =
            pathname === pathConstant.PATH_HOME ||
            pathname === pathConstant.PATH_CUSTOMERSERVICE_NOTICE ||
            pathname === pathConstant.PATH_CUSTOMERSERVICE_QNA ||
            pathname === pathConstant.PATH_CUSTOMERSERVICE_FAQ
                ? true
                : false;
        return (
            <>
                <ScrollToTop {...this.props} />
                <Header {...this.props} />
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? <Route key={idx} path={route.path} exact={route.exact} render={(props) => <route.component {...props} />} /> : null;
                    })}
                    <Route exact path="*" render={() => <Redirect to="/service-admin" />} />
                </Switch>
                <Footer {...this.props} />
                {showFloatingBar && <FloatingBar {...this.props} />}
            </>
        );
    }
}
