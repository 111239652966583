/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TenantManagementLnbView, ApprovalTenantDetail } from "_containers";
import { fetchTenantList, getLocalSession } from "_services";
import moment from "moment";
import { requestParam } from "_helpers";
import MaterialReactTable from 'material-react-table';

class ApprovalManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alltenants: [],
            tenants: [],
            openTenantDetail: false,
            selectedTenantid: "",
            rowCount: 10,
            page: 1,
            waitCount: 0
        };

        this._handlePageChange = this._handlePageChange.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleTenantDetail = this._handleTenantDetail.bind(this);
        this._openTenantAdminPortal = this._openTenantAdminPortal.bind(this);
    }
    _openTenantAdminPortal(tenant) {
        let params = {
            token: getLocalSession().idToken,
            tenantid: tenant.tenantid,
            tenanturl: tenant.tenanturl,
            email: tenant.email
        };
        let url = `${process.env.REACT_APP_PORTALURL}/service-linkage/admin-portal?${requestParam(params)}`;
        window.open(url, "_blank");
    }
    _handleClose() {
        this.setState({
            ...this.state,
            openTenantDetail: false,
            selectedTenantid: ""
        });

        // this._loadData();
    }

    _handleTenantDetail(tenantid) {
        this.setState({
            ...this.state,
            openTenantDetail: true,
            selectedTenantid: tenantid
        });
    }

    componentDidMount() {
        this._loadData();
    }

    _loadData() {
        this.props.fetchTenantList({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    alltenants: result,
                    tenants: result.filter((item) => item.status === "1"),
                    waitCount: result.filter((item) => item.status === "1").length
                });
            }
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber
        });
    }

    render() {
        const columns = [
            {
                header: "회사명", accessorKey: "compnm", size: 120
            },
            {
                header: "관리자명", accessorKey: "adminnm", size: 100
            },
            {
                header: "전화번호", accessorKey: "phone", size: 120
            },
            {
                header: "사업자번호", accessorKey: "brnumber", size: 100
            },
            {
                header: "주소", accessorKey: "address", size: 145
            },
            {
                header: "가입일", accessorKey: "createdtime", size: 120,
                accessorFn: (item) => moment(item.createdtime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
            },
        ]
        return (
            <>
                <div className="content">
                    <div className="inner">
                        <TenantManagementLnbView {...this.props} waitCount={this.state.waitCount} />

                        <div className="main_cont">
                            <div className="cont_header">
                                <h3 className="title">승인대기</h3>
                            </div>
                            <div className="cont_body">
                                <section className="tab_sect">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="notiAll" aria-labelledby="notiAll-tab">
                                            <div className="table_area">
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={this.state.tenants || []}
                                                    initialState={{ showGlobalFilter: true, density: "compact" }}
                                                    localization={{
                                                        noRecordsToDisplay: "데이터가 없습니다.",
                                                        noResultsFound: "데이터를 찾을 수 없습니다.",
                                                    }}
                                                    muiTablePaginationProps={{
                                                        SelectProps: { native: false },
                                                        labelRowsPerPage: '보기',
                                                        labelDisplayedRows: function defaultLabelDisplayedRows({ page }) { return `${page + 1} 페이지`; }
                                                    }}
                                                    muiSearchTextFieldProps={{
                                                        placeholder: '검색',
                                                        sx: { minWidth: '18rem' },
                                                        variant: 'outlined',
                                                    }}
                                                    muiTableBodyRowProps={({ row }) => ({
                                                        onClick: () => { this._handleTenantDetail(row.original.tenantid) },
                                                        sx: { cursor: 'pointer' },
                                                    })}
                                                    enableColumnFilters={false}
                                                    enableDensityToggle={false}
                                                    enableHiding={false}
                                                    enableSorting={true}
                                                    enableFullScreenToggle={false}
                                                    enableGlobalFilter={true}
                                                    enableFilters={true}
                                                    enableBottomToolbar={true}
                                                    enableTableHead={true}
                                                    enableTableFooter={true}
                                                    enableToolbarInternalActions={true}
                                                    enableTopToolbar={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.openTenantDetail && <ApprovalTenantDetail tenantid={this.state.selectedTenantid} open={this.state.openTenantDetail} onClose={this._handleClose} />}
            </>
        );
    }
}
function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTenantList,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(ApprovalManagement);
export { connected as ApprovalManagement };
