import { requestUrl, requestHeaders, requestParam, handleResponse, requestBody } from "_helpers";
import {
    fetchBulletinListRequest,
    fetchBulletinListSuccess,
    fetchBulletinListError,
    addBulletinRequest,
    addBulletinSuccess,
    addBulletinError,
    modifyBulletinRequest,
    modifyBulletinSuccess,
    modifyBulletinError,
    removeBulletinRequest,
    removeBulletinSuccess,
    removeBulletinError
} from "_actions";

// 게시글 목록 조회
export function fetchBulletinList(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/cs/bulletins?${requestParam(params)}`);
        const requestOptions = {
            credentials: "same-origin",
            method: "GET",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        dispatch(fetchBulletinListRequest());
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => {
                callback(null, response);
                dispatch(fetchBulletinListSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(fetchBulletinListError(error));
            });
    };
}

// 답글
export function answerQnA(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/cs/bulletins/answer`);
        const requestOptions = {
            credentials: "same-origin",
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => {
                callback(null, response);
            })
            .catch((error) => {
                callback(error);
            });
    };
}

// 글 추가
export function addBulletin(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/cs/bulletins`);
        const requestOptions = {
            credentials: "same-origin",
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        dispatch(addBulletinRequest());
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => {
                callback(null, response);
                dispatch(addBulletinSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(addBulletinError(error));
            });
    };
}

// 글 수정
export function modifyBulletin(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/cs/bulletins/${params.bltid}`);
        const requestOptions = {
            credentials: "same-origin",
            method: "PUT",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        dispatch(modifyBulletinRequest());
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => {
                callback(null, response);
                dispatch(modifyBulletinSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(modifyBulletinError(error));
            });
    };
}

// 글 삭제
export function removeBulletin(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/cs/bulletins/${params.bltid}`);
        const requestOptions = {
            credentials: "same-origin",
            method: "DELETE",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        dispatch(removeBulletinRequest());
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response))
            .then((response) => {
                callback(null, response);
                dispatch(removeBulletinSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(removeBulletinError(error));
            });
    };
}
