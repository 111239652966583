/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";

export class Pagination extends Component {
    /**
     *
     * @param {*} props
     * {
     *  totalCount: 10,
     *  rowCount: 10,
     *  page: 1
     *  onPageMove: () => {}
     * }
     */
    constructor(props) {
        super(props);

        this._handlePageMove = this._handlePageMove.bind(this);
    }
    _handlePageMove(e, page) {
        e.preventDefault();

        this.props.onPageMove(page);
    }
    render() {
        const { totalCount, rowCount, page } = this.props;
        const pageCount = Math.ceil(totalCount / rowCount);
        let maxPage = Math.ceil(page / 10) * 10;
        if (maxPage > pageCount) {
            maxPage = pageCount;
        }
        let minPage = Math.ceil(maxPage / 10) * 10 - 9;
        let pages = [];
        if (maxPage > 0) {
            for (let i = minPage; i <= maxPage; i++) {
                pages.push(i);
            }
        } else {
            minPage = 1;
            pages.push(1);
        }

        return (
            <>
                <ul className="pagination justify-content-center">
                    <li className={"page-item " + (minPage === 1 ? "disabled" : "")}>
                        <a href="" className="page-link prev" tabIndex="-1" onClick={(e) => this._handlePageMove(e, minPage - 1)}></a>
                    </li>

                    {pages.map((item, index) => (
                        <li key={index} className={"page-item " + (item === page ? "active" : "") + (page === item ? " disabled" : "")}>
                            <a href="" className="page-link" onClick={(e) => this._handlePageMove(e, item)}>
                                {item}
                            </a>
                        </li>
                    ))}

                    <li className={"page-item " + (maxPage >= pageCount ? "disabled" : "")}>
                        <a href="" className="page-link next" onClick={(e) => this._handlePageMove(e, maxPage + 1)}></a>
                    </li>
                </ul>
            </>
        );
    }
}
