/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TenantManagementLnbView } from "_containers";
import { adminLoginHistory } from "_services";
import MaterialReactTable from 'material-react-table';

class TenantManagementLoginHist extends Component {
  constructor(props) {
    super(props);
    const state = props.location.state;
    this.state = {
      waitCount: state.waitCount,
      rowCount: 10,
      page: 1,
      compnm: state.compnm,
      selectedTenant: { ...props.location.state.data },
      hist: [],
      params: {
        startdate: state.searchDate.startdate,
        enddate: state.searchDate.enddate,
        tenantid: state.tenantId
      }
    };

    this._handlePageChange = this._handlePageChange.bind(this);
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData() {
    this.props.adminLoginHistory(this.state.params, (error, result) => {
      if (result) {
        this.setState({
          ...this.state,
          hist: result
        });
      }
    });
  }
  _handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      currentPage: pageNumber
    });
  }

  render() {
    const columns = [
      {
        header: "이메일", accessorKey: "adminemail"
      },
      {
        header: "접속IP", accessorKey: "clientip", size: 100
      },
      {
        header: "접속시간", accessorKey: "logintime", size: 90
      },
    ]
    return (
      <>
        <div className="content">
          <div className="inner">
            <TenantManagementLnbView {...this.props} waitCount={this.state.waitCount} />
            <div className="main_cont">
              <div className="cont_header">
                <h3 className="title">{this.state.compnm} 접속이력</h3>
              </div>

              <div className="cont_body">
                <section className="view_sect">
                  <div className="view_body">
                    <MaterialReactTable
                      columns={columns}
                      data={this.state.hist || []}
                      initialState={{ showGlobalFilter: true }}
                      localization={{
                        noRecordsToDisplay: "데이터가 없습니다.",
                        noResultsFound: "데이터를 찾을 수 없습니다.",
                      }}
                      muiTablePaginationProps={{
                        SelectProps: { native: false },
                        labelRowsPerPage: '보기',
                        labelDisplayedRows: function defaultLabelDisplayedRows({ page }) { return `${page + 1} 페이지`; }
                      }}
                      muiSearchTextFieldProps={{
                        placeholder: '검색',
                        sx: { minWidth: '18rem' },
                        variant: 'outlined',
                      }}
                      enableColumnFilters={false}
                      enableDensityToggle={false}
                      enableHiding={false}
                      enableSorting={true}
                      enableFullScreenToggle={false}
                      enableGlobalFilter={true}
                      enableFilters={true}
                      enableBottomToolbar={true}
                      enableTableHead={true}
                      enableTableFooter={true}
                      enableToolbarInternalActions={true}
                      enableTopToolbar={true}

                      renderTopToolbarCustomActions={() => (
                        <div className="form-group date-picker-container">
                          <div className="login-hist-table-title-left">기간 : {this.state.params.startdate} ~ {this.state.params.enddate}</div>
                        </div>
                      )}
                    />
                  </div>
                  <div className="btn_area login-hist-btn_area">
                    <div className="area_item">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                      >
                        목록
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      adminLoginHistory,
      dispatch
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(TenantManagementLoginHist);
export { connected as TenantManagementLoginHist };
