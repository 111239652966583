/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { getLocalSession, cleanLocalSession } from "_services";
import { pathConstant } from "_constants";
import { firebaseSignOut } from "_services";
import logoImg from "_asset/images/logo.svg";

export class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleBugerMenu: false
        };

        this._handleBurgerMenu = this._handleBurgerMenu.bind(this);
        this._handleMenuClick = this._handleMenuClick.bind(this);
        this._handleMyPage = this._handleMyPage.bind(this);
        this._handleLogout = this._handleLogout.bind(this);
    }
    componentDidMount() {
        const session = getLocalSession();
        const loggedIn = session && session.idToken ? true : false;
        if (!loggedIn) {
            this.props.history.push(pathConstant.PATH_LOGIN);
        }
    }
    _handleBurgerMenu() {
        this.setState({
            ...this.state,
            toggleBugerMenu: !this.state.toggleBugerMenu
        });
    }
    _handleMenuClick(pathname, e) {
        if (e) {
            e.preventDefault();
        }

        const { toggleBugerMenu } = this.state;
        if (toggleBugerMenu) {
            this.setState({
                ...this.state,
                toggleBugerMenu: !this.state.toggleBugerMenu
            });
        }
        this.props.history.push(pathname);
    }
    _handleMyPage() {}
    _handleLogout() {
        firebaseSignOut()
            .then(() => {
                this._logout();
            })
            .catch((error) => {
                this._logout();
            });
    }
    _logout() {
        cleanLocalSession();
        this.props.history.push(pathConstant.PATH_LOGIN);
    }
    render() {
        const { toggleBugerMenu } = this.state;
        const { pathname } = this.props.location;
        const session = getLocalSession();
        const loggedIn = session && session.idToken ? true : false;
        return (
            <>
                <header className={"header " + (toggleBugerMenu ? "visible" : "")}>
                    <div className="inner">
                        <h1 className="logo">
                            <a
                                href=""
                                onClick={(e) => {
                                    if (loggedIn) {
                                        // this._handleMenuClick(pathConstant.PATH_HOME, e);
                                    }
                                }}
                            >
                                <img src={logoImg} alt="아이브웍스 로고" />
                            </a>
                        </h1>
                        <button className="ico_btn navbar_toggle" onClick={this._handleBurgerMenu}>
                            <span className="menu_btn"></span>
                        </button>
                        <div className="navbar">
                            <ul className="nav" id="gnb">
                                <li className={pathname.startsWith(pathConstant.PATH_TENANT_MANAGEMENT) ? "active" : ""}>
                                    <a
                                        href=""
                                        onClick={(e) => {
                                            this._handleMenuClick(pathConstant.PATH_TENANT_MANAGEMENT, e);
                                        }}
                                    >
                                        테넌트 관리
                                    </a>
                                </li>
                                <li className={pathname.startsWith(pathConstant.PATH_CUSTOMERSERVICEE) ? "active" : ""}>
                                    <a
                                        href=""
                                        onClick={(e) => {
                                            this._handleMenuClick(pathConstant.PATH_CUSTOMERSERVICE_NOTICE, e);
                                        }}
                                    >
                                        고객지원
                                    </a>
                                </li>
                            </ul>

                            <div className="btn_area">
                                {loggedIn && (
                                    <button className="btn btn_y_md" onClick={this._handleLogout}>
                                        로그아웃
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </header>
                {toggleBugerMenu && <div className="navbar_mask"></div>}
            </>
        );
    }
}
