/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TenantManagementLnbView } from "_containers";
import { fetchTenantList, adminLoginStatistics } from "_services";
import { pathConstant } from "_constants";
import MaterialReactTable from 'material-react-table';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';

class TenantManagementLoginStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alltenants: [],
      tenants: [],
      loginStatistics: [],
      openTenantDetail: false,
      selectedTenantid: "",
      rowCount: 10,
      page: 1,
      waitCount: 0,
      params: {
        startdate: moment().startOf('month').format('YYYY-MM-DD'),
        enddate: moment().format('YYYY-MM-DD'),
      }
    };
    this._handleClose = this._handleClose.bind(this);
    this._requestPayment = this._requestPayment.bind(this);
  }

  _requestPayment() {
    this.props.adminLoginStatistics(this.state.params, (error, result) => {
      this.setState({
        ...this.state,
        loginStatistics: result
      })
    });
  }

  _handleClose() {
    this.setState({
      ...this.state,
      openTenantDetail: false,
      selectedTenantid: ""
    });
  }

  _handleLoginHist(item) {
    this.props.history.push({
      pathname: pathConstant.PATH_TENANT_MANAGEMENT_LOGIN_HIST,
      state: {
        //검색
        searchDate: this.state.params,
        waitCount: this.state.waitCount,
        tenantId: item.tenantid,
        compnm: item.compnm,
      }
    });
  }

  componentDidMount() {
    this._requestPayment();
    this._loadData();
  }

  _loadData() {
    this.props.fetchTenantList({}, (error, result) => {
      if (result) {
        this.setState({
          ...this.state,
          alltenants: result,
          tenants: result,
          waitCount: result.filter((item) => item.status === "1").length
        });
      }
    });
  }

  _handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      currentPage: pageNumber
    });
  }

  render() {
    const columns = [
      {
        header: "회사명", accessorKey: "compnm"
      },
      {
        header: "마지막접속시간", accessorKey: "lastlogintime", size: 90
      },
      {
        header: "접속 횟수", accessorKey: "logincnt", size: 90
      },
    ]

    return (
      <>
        <div className="content">
          <div className="inner">
            <TenantManagementLnbView {...this.props} waitCount={this.state.waitCount} />

            <div className="main_cont">
              <div className="cont_header">
                <h3 className="title">접속기록관리</h3>
              </div>
              <div className="cont_body">
                <section className="tab_sect">
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="notiAll" aria-labelledby="notiAll-tab">
                      <div className="table_area">
                        <MaterialReactTable
                          columns={columns}
                          data={this.state.loginStatistics || []}
                          initialState={{ showGlobalFilter: true }}
                          localization={{
                            noRecordsToDisplay: "데이터가 없습니다.",
                            noResultsFound: "데이터를 찾을 수 없습니다.",
                          }}
                          muiTablePaginationProps={{
                            SelectProps: { native: false },
                            labelRowsPerPage: '보기',
                            labelDisplayedRows: function defaultLabelDisplayedRows({ page }) { return `${page + 1} 페이지`; }
                          }}
                          muiSearchTextFieldProps={{
                            placeholder: '검색',
                            sx: { minWidth: '18rem' },
                            variant: 'outlined',
                          }}
                          muiTableBodyRowProps={({ row }) => ({
                            onClick: () => { this._handleLoginHist(row.original) },
                            sx: { cursor: 'pointer' },
                          })}
                          showEmptyDataSourceMessage={true}
                          enableColumnFilters={false}
                          enableDensityToggle={false}
                          enableHiding={false}
                          enableSorting={true}
                          enableFullScreenToggle={false}
                          enableGlobalFilter={true}
                          enableFilters={true}
                          enableBottomToolbar={true}
                          enableTableHead={true}
                          enableTableFooter={true}
                          enableToolbarInternalActions={true}
                          enableTopToolbar={true}

                          renderTopToolbarCustomActions={() => (
                            <div className="form-group date-picker-container">
                              <div>검색일자 : </div>
                              <div className="form-group date-picker-container">
                                <DatePicker
                                  id="startTime"
                                  name="startTime"
                                  className="form-control date-picker-table"
                                  dateFormat="yyyy-MM-dd"
                                  locale={ko}
                                  value={this.state.params.startdate}
                                  onChange={(nextDate) => {
                                    let isData = moment(nextDate).isAfter(this.state.params.startdate);
                                    this.setState({
                                      ...this.state,
                                      params: {
                                        ...this.state.params,
                                        startdate: moment(nextDate).format('YYYY-MM-DD'),
                                        enddate: isData ? moment(nextDate).format('YYYY-MM-DD') : this.state.params.enddate
                                      }
                                    })
                                  }}
                                />
                                ~
                                <DatePicker
                                  id="endTime"
                                  name="endTime"
                                  className="form-control  date-picker-table"
                                  dateFormat="yyyy-MM-dd"
                                  locale={ko}
                                  value={this.state.params.enddate}
                                  onChange={(nextDate) => {
                                    this.setState({
                                      ...this.state,
                                      params: {
                                        ...this.state.params,
                                        enddate: moment(nextDate).format('YYYY-MM-DD')
                                      }
                                    })
                                  }}
                                />
                              </div>
                              <button
                                type="button"
                                className="btn login-statis-check-btn"
                                onClick={this._requestPayment}
                              >
                                조회
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTenantList,
      adminLoginStatistics,
      dispatch
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(TenantManagementLoginStatistics);
export { connected as TenantManagementLoginStatistics };