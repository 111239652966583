import { requestUrl, requestHeaders, requestParam, handleResponse, requestBody } from "_helpers";
import {
    fetchTenantListRequest,
    fetchTenantListError,
    fetchTenantListSuccess,
    fetchTenantDetailRequest,
    fetchTenantDetailError,
    fetchTenantDetailSuccess,
    fetchTenantApprovalRequest,
    fetchTenantApprovalError,
    fetchTenantApprovalSuccess
} from "_actions";
import moment from "moment";

// 테넌트 목록
export function fetchTenantList(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants`);
        const requestOptions = {
            credentials: "same-origin",
            method: "GET",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        dispatch(fetchTenantListRequest());
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
                dispatch(fetchTenantListSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(fetchTenantListError(error));
            });
    };
}

// 테넌트 상세
export function fetchTenantDetail(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants/${params.tenantid}`);
        const requestOptions = {
            credentials: "same-origin",
            method: "GET",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        dispatch(fetchTenantDetailRequest());
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
                dispatch(fetchTenantDetailSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(fetchTenantDetailError(error));
            });
    };
}

// 테넌트 승인
export function setTenantApproval(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants/${params.tenantid}/approval`);
        const requestOptions = {
            credentials: "same-origin",
            method: "PUT",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        dispatch(fetchTenantApprovalRequest());
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
                dispatch(fetchTenantApprovalSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(fetchTenantApprovalError(error));
            });
    };
}

// 결제 로그
export function fetchTenantPaymentLogs(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants/${params.tenantid}/payment_logs`);
        const requestOptions = {
            credentials: "same-origin",
            method: "GET",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((error) => {
                callback(error);
            });
    };
}

// 결제 요청
export function fetchRequestPayment(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants/${params.tenantid}/request-payment`);
        const requestOptions = {
            credentials: "same-origin",
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((error) => {
                callback(error);
            });
    };
}

// 메일 발송
export function fetchSendMail(params = {}, callback = (error, result) => { }) {
    //params.to = "mhjung@aiblab.co.kr";
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/send-mail`);
        const requestOptions = {
            credentials: "same-origin",
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function fetchDeleteTenant(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants/${params.tenantid}`);
        const requestOptions = {
            credentials: "same-origin",
            method: "DELETE",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, true);
            })
            .catch((error) => {
                callback(error);
            });
    };
}

// Gateways
export function fetchGatewayList(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/gateways`);
        const requestOptions = {
            credentials: "same-origin",
            method: "GET",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
            })
            .catch((error) => {
                callback(error);
            });
    };
}
export function fetchAssignGateway(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/gateways/assign`);
        const requestOptions = {
            credentials: "same-origin",
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, true);
            })
            .catch((error) => {
                callback(error);
            });
    };
}
export function fetchReleaseGateway(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/gateways/release`);
        const requestOptions = {
            credentials: "same-origin",
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(params)
        };
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, true);
            })
            .catch((error) => {
                callback(error);
            });
    };
}


// 관리자 로그인 기록
export function adminLoginStatistics(params = {}, callback = (error, result) => { }) {
    let times = {
        startdate: moment(params.startdate).format("YYYY-MM-DD 00:00:00"),
        enddate: moment(params.enddate).format("YYYY-MM-DD 23:59:59")
    }
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/admin-login-hist/statistics?${requestParam(times)}`);
        const requestOptions = {
            credentials: "same-origin",
            method: "GET",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        dispatch(fetchTenantListRequest());
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
                dispatch(fetchTenantListSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(fetchTenantListError(error));
            });
    };
}

// 관리자 로그인 이력
export function adminLoginHistory(params = {}, callback = (error, result) => { }) {
    let startdate = moment(params.startdate).format("YYYY-MM-DD 00:00:00");
    let enddate = moment(params.enddate).format("YYYY-MM-DD 23:59:59");
    let times = {
        startdate: startdate,
        enddate: enddate,
        tenantid: params.tenantid
    }
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/admin-login-hist?${requestParam(times)}`);
        const requestOptions = {
            credentials: "same-origin",
            method: "GET",
            headers: requestHeaders({ "Content-Type": "application/json" })
        };
        dispatch(fetchTenantListRequest());
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
                dispatch(fetchTenantListSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(fetchTenantListError(error));
            });
    };
}

// 테넌트 관리자 가입경로 변경
export function adminSignupChannelChange(params = {}, callback = (error, result) => { }) {
    return (dispatch) => {
        const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-console/mgmt/tenants/${params.tenantid}/signup-channel`);
        const requestOptions = {
            credentials: "same-origin",
            method: "PUT",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody({ signup_channel: params.signup_channel })
        };
        dispatch(fetchTenantApprovalRequest());
        return fetch(url, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.data);
                dispatch(fetchTenantApprovalSuccess(response));
            })
            .catch((error) => {
                callback(error);
                dispatch(fetchTenantApprovalError(error));
            });
    };
}