/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchBulletinList } from "_services";

class Home extends Component {
    constructor(props) {
        super(props);

        this._handleJoin = this._handleJoin.bind(this);
    }
    componentDidMount() {
        this._loadData();
    }
    render() {
        return (
            <div className="content">
                <h1>서비스 관리자 홈화면</h1>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { bulletin } = state;
    return {
        bulletinListData: bulletin.bulletinListData
    };
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchBulletinList,
            dispatch
        },
        dispatch
    );
const connected = connect(mapStateToProps, mapDispatchToProps)(Home);
export { connected as Home };
