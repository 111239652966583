import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { loading } from "./loading.reducer";
import { login } from "./login.reducer";
import { alert } from "./alert.reducer";
import { bulletin } from "./customerservice/bulletin.reducer";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        loading,
        login,
        alert,
        bulletin
    });

export default createRootReducer;
