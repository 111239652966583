import React, { Component } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import { history } from "_helpers";
import { Alert, Loading } from "_components";
import { AppLayout, Login } from "_containers";
import { getLocalSession } from "_services";
import "_asset/css/main.css";
import "_asset/css/media_query.css";
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUserAuthenticated: false
        };
    }
    componentDidMount() {
        const session = getLocalSession();
        const loggedIn = session && session.idToken ? true : false;
        this.setState({ isUserAuthenticated: loggedIn });
    }
    render() {
        return (
            <ConnectedRouter history={history}>
                <Loading />
                <Alert />
                <Switch>
                    <Route path="/login" name="Login" render={(props) => <Login {...props} />} />
                    <Route path="/service-admin" name="AppLayout" render={(props) => <AppLayout {...props} />} />
                    <Route
                        exact
                        path="*"
                        render={() => {
                            return this.state.isUserAuthenticated ? <Redirect to="/service-admin" /> : <Redirect to="/login" />;
                        }}
                    />
                </Switch>
            </ConnectedRouter>
        );
    }
}

export default App;
