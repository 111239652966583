import { ALERT_CLOSE, ALERT_OPEN } from "_actions/alert.action";

const initialState = {
    open: false
};

export function alert(state = initialState, action) {
    switch (action.type) {
        case ALERT_OPEN:
            return {
                open: true,
                message: action.message,
                confirm: action.confirm,
                labelOk: action.labelOk,
                labelNo: action.labelNo,
                handleOk: action.handleOk,
                handleNo: action.handleNo
            };
        case ALERT_CLOSE:
            return {
                open: false
            };
        default:
            return state;
    }
}
